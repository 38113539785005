import React from "react";
import './AforosReporteMapa.scss'
import Mapa from "../../components/organisms/Mapa/Mapa";


const AforosReporteMapa = () => {
    return (
        <div className="AforosReporteMapa">
            <Mapa>
               
            </Mapa>
        </div>
    )
}
export default AforosReporteMapa