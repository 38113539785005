import axios from "axios"

const URL_api = process.env.REACT_APP_URL_API



export const getRoutes = async (idClient,setData)=>
{
    await axios.get(`${URL_api}/aforo/getRutasEnCurso?id_cliente=${idClient}`)
    .then((res)=>
    {
        console.log("res",res);
        const data = []
        if(res.data.data)
        {
            res.data.data.map((item)=>{
                data.push({id:item.ID_BITACORA,ruta:item.RUTA,confirmada:item.CONFIRMADA})
            })
            setData(data)
        }
        else{
            setData(null)
        }
    })
    .catch((error)=>
    {
        console.log("error",error);
    })
}