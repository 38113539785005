import React, { useEffect, useState } from "react";
import './UserHeader.scss'
import userIcon from '../../../assets/icons/user.svg'
import OpcionesUsiario from "../OpcionesUsiario/OpcionesUsiario";
import { desEncriptar } from "../../../utilidades/encriptacion";

const UserHeader = ({setAuth}) => {
    const [datos,setDatos] = useState(null)
    useEffect(()=>
    {
        const clave = process.env.REACT_APP_CLAVE_INCRIPTACION
        let dataCliente = desEncriptar(localStorage.getItem("dataClient"),clave)
        dataCliente = JSON.parse(dataCliente)
        setDatos(dataCliente)
    },[])
    const [disponible, setDisponible] = useState(true)
    const [mostrarOpciones, setMostrarOpciones] = useState(false)
    return (
        <div className="UserHeader" onClick={() => { setMostrarOpciones(!mostrarOpciones) }}>
            <div className="imageContainer">
                <div className="informacionUsuario">
                    <div className="imagen">
                        {/* <img src={datos?.data.attributes.fotografia?datos?.data.attributes.fotografia:userIcon} alt="" /> */}
                        <img src={userIcon} alt="" />

                    </div>
                </div>
                <div className="estado">
                    {
                        disponible ?
                            <>Disponible</> :
                            <>Ocupado</>
                    }
                </div>

            </div>
            {
                mostrarOpciones ?
                    <>
                        <OpcionesUsiario setAuth={setAuth} estado = {disponible}></OpcionesUsiario>
                    </> : null
            }
        </div>
    )
}
export default UserHeader