import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AdministradorUsuarios from "../../views/AdministradorUsuarios/AdministradorUsuarios";
import AdministradoresUsuariosCrear from "../../views/AdministradoresUsuariosCrear/AdministradoresUsuariosCrear";
import AdministradorUsuariosEditar from "../../views/AdministradorUsuariosEditar/AdministradorUsuariosEditar";

const AdministradorRoutes = () => {
    return (
        <Routes>
            <Route exact path="/administrador/usuarios" element={
                <Suspense fallback={<>skeleton</>}>
                    <AdministradorUsuarios></AdministradorUsuarios>
                </Suspense>
            } />
             <Route exact path="/administrador/usuarios/:id/editar" element={
                <Suspense fallback={<>skeleton</>}>
                    <AdministradorUsuariosEditar></AdministradorUsuariosEditar>
                </Suspense>
            } />
             <Route exact path="/administrador/usuarios/crear" element={
                <Suspense fallback={<>skeleton</>}>
                    <AdministradoresUsuariosCrear></AdministradoresUsuariosCrear>
                </Suspense>
            } />
        </Routes>
    )
}
export default AdministradorRoutes
