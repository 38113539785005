import React from "react";
import './InputCodigo.scss'
import ReactCodeInput from "react-verification-code-input";


const InputCodigo = ({setCodigo})=>
{

    return(
        <div className="InputCodigo" id="codigoVerificacion">
            <ReactCodeInput fields={5} fieldWidth={50} fieldHeight={60} onComplete={(e) => setCodigo(e)} autoFocus={true}></ReactCodeInput>
        </div>
    )
}
export default InputCodigo