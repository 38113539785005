import React, { useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import DashboardRoutes from "./Dashboard/DashboardRoutes";
import SideMenu from "../components/organisms/SideMenu/SideMenu";
import UsuarioRutas from "./Usuario/UsuarioRutas";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import LoginRoutes from "./LoginRoutes/LoginRoutes";
import AforosRouters from "./AforosRouters/AforosRouters";
import { desEncriptar, encriptar } from "../utilidades/encriptacion";
import AdministradorRoutes from "./AdministradorRoutes/AdministradorRoutes";
const MainRouter = () => {
    const [auth, setAuth] = useState(false)
    useEffect(()=>
    {
        const clave = process.env.REACT_APP_CLAVE_INCRIPTACION
        if(localStorage.getItem('auth'))
        {
            const autLocal = localStorage.getItem('auth')
            if(desEncriptar(autLocal,clave) == "false")
            {
                setAuth(false)
            }
            else
            {
                setAuth(true)
            }
        }
        else
        {
            localStorage.setItem('auth', encriptar(`${auth}`,clave));
        }
    },[])
    return (

        <BrowserRouter>
            {
                auth ?
                    <SideMenu setAuth={setAuth}>
                        <DashboardRoutes></DashboardRoutes>
                        <AforosRouters></AforosRouters>
                        <UsuarioRutas ></UsuarioRutas>
                        <AdministradorRoutes></AdministradorRoutes>
                    </SideMenu>:
                    <LoginRoutes setAuth = {setAuth}></LoginRoutes>
            }
        </BrowserRouter>
    )
}
export default MainRouter