import React, { useEffect, useState } from "react";
import './TarjetaTabla.scss'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatearRabla } from "./funciones";
import { Skeleton } from "primereact/skeleton";
const TarjetaTabla = ({ titulo, datos, width = "100%",skeleton }) => {
    //escuchador para el responsive

    const [tamañoPantalla, setTamañoPantalla] = useState({
        width: window.innerWidth
    })
    const [datosFormateados, setDatosFormateados] = useState(null)
    const cambioDeTamño = () => {
        setTamañoPantalla({
            width: window.innerWidth
        })
    }
    useEffect(() => {
        // Suscribirse al evento de cambio de tamaño
        window.addEventListener('resize', cambioDeTamño);

        // Función de limpieza para desuscribirse del evento
        return () => {
            window.removeEventListener('resize', cambioDeTamño);
        }
    }, [])
    useEffect(() => {
        if(!skeleton)
        {
            formatearRabla(datos, setDatosFormateados)
        }
    }, [datos])
    return (
        <div className="TarjetaTabla" style={{ width: tamañoPantalla.width > 1255 ? width : "100%" }}>
            <div className="titulo">{titulo}</div>
            <hr />
            <div className="tablaContenedor">
                {
                    datosFormateados ?
                        <>
                            <DataTable value={datosFormateados} size="Small" stripedRows tableStyle={{ minWidth: '80px' }}>
                                <Column field="Autobus" header="Autobus" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="Automovil" header="Automovil" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="CamionChico" header="Camion chico" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="CamionGrande" header="Camion grande" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="Camioneta" header="Camioneta" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="Minivan" header="Minivan" body={skeleton?<Skeleton />:null}></Column>
                                <Column field="Sprinter" header="Sprinter" body={skeleton?<Skeleton />:null}></Column>
                            </DataTable>
                        </>:null
                }
            </div>
        </div>
    )
}
export default TarjetaTabla