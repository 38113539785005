import React, { useEffect, useState } from "react";
import './AdministradoresUsuariosCrear.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import { Dropdown } from "primereact/dropdown";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import InputText from "../../components/atoms/InputText/InputText";
import Boton from "../../components/atoms/Boton/Boton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AdministradoresUsuariosCrear = () => {
    const navigate = useNavigate()
    const [inputLista, setInputLista] = useState({
        // idUsuario:{ value: null, validationType: "empty"},
        Tipodeusuario: { value: null, validationType: "empty" },//select
        email:{ value: null, validationType: "email"},
        nombre:{ value: null, validationType: "empty"},
        empresa:{ value: null, validationType: "empty"},//select
        password:{ value: null, validationType: "empty"},
        telefono:{ value: null, validationType: "optional"},
    })
    useEffect(() => {
        for (const Elemento in inputLista) {
            if (document.getElementById(Elemento)) {
                ColorValidation(Elemento, inputLista)
            }
        }
    }, [inputLista])
    const etiquetas = [
        { name: 'Administrador', code: 'administrador' },
        { name: 'Inplant', code: 'inplant' },
        { name: 'Coordinador', code: 'coordinador' },
        { name: 'Cliente', code: 'cliente' },

    ];
    const empresas = [
        { name: '163 - PLEXUS', code: '163' },
    ];
    const guardarUsuario = ()=>
    {
        console.log("inputLista",inputLista);
        if(SubmitValidation(inputLista,setInputLista))
        {
            Swal.fire({
                icon: "success",
                title: "Nuevo usuario guardado",
                showConfirmButton: false,
                timer: 1500
              });
              navigate("/administrador/usuarios")
        }
    }
    return (
        <div className="AdministradoresUsuariosCrear">
            <DefaultCard>
                <div className="titulo">Agregar nuevo usuario</div>
                <hr />
                <div className="IdUsuario">
                    <InputText placeholder={"Ingresa el nombre del usuario"} id="nombre" title="Nombre de usuario" onChange={(e)=>{UpdateValue(e,"nombre",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText placeholder={"Ingresa el email del usuario"} id="email" title="Correo electrónico" onChange={(e)=>{UpdateValue(e,"email",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText type="password" placeholder={"Contraeña"} id="password" title="Contraseña" onChange={(e)=>{UpdateValue(e,"password",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText type="number" placeholder={"teléfono"} id="telefono" title="teléfono" onChange={(e)=>{UpdateValue(e,"telefono",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="drop">
                    <div className="tituloDropd">Seleccionar el tipo de usuario</div>
                    <Dropdown id="Tipodeusuario" value={inputLista.Tipodeusuario.value} onChange={(e) => { UpdateValue(e, "Tipodeusuario", inputLista, setInputLista) }} options={etiquetas} optionLabel="name" placeholder="Selecciona el ipo de usuario" filter className="dropclass" />
                </div>
                <div className="drop">
                    <div className="tituloDropd">Seleccionar la empresa</div>
                    <Dropdown id="empresa" value={inputLista.empresa.value} onChange={(e) => { UpdateValue(e, "empresa", inputLista, setInputLista) }} options={empresas} optionLabel="name" placeholder="Selecciona la empresa" filter className="dropclass" />
                </div>
                <div className="guardar">
                    <Boton onClick={()=>{guardarUsuario()}}>Guardar usuario</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}
export default AdministradoresUsuariosCrear