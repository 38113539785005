import React from "react";
import './EstadoActivoInactivo.scss'
const EstadoActivoInactivo = ({ onClick, value,noTexto=true }) => {
    return (
        <div className="EstadoActivoInactivo">
            {
                noTexto == true?
                value ?
                    <>
                        <div className="titulo">Activo</div>
                    </> :
                    <>
                        <div className="titulo">Inactivo</div>
                    </>
                :null
            }
            <div className={`estadoContenedor ${value?"activo":"inactivo"}`} onClick={onClick}>
                {
                    value ?
                        <>
                            <div className={`opcion`}>Activado</div>
                        </> :
                        <>
                            <div className={`opcion`}>Deshabilitado</div>
                        </>
                }
            </div>
        </div>
    )
}
export default EstadoActivoInactivo