import axios from "axios"

const api_URL = process.env.REACT_APP_URL_API

export const getList = async (setTurnos)=>
{
    await axios.get(`${api_URL}/aforo/getTurnos`)
    .then((res)=>
    {
        console.log("res",res);
        setTurnos(res.data.data)
    })
    .catch((error)=>
    {
        console.log("error",error);
    })
}