import React, { useEffect, useState } from "react";
import './Mapa.scss'
import { GoogleMap, LoadScript, MarkerF, OverlayViewF, Polyline, PolylineF, useJsApiLoader } from '@react-google-maps/api';
import trazoJson from '../../../assets/json/aforos/ReporteMapa.json'
import ModalReporte from "../../molecules/ModalReporte/ModalReporte";
import ModalMonitorReporte from "../ModalMonitorReporte/ModalMonitorReporte";
import PersonasModal from "../../molecules/PersonasModal/PersonasModal";
import Boton from "../../atoms/Boton/Boton";
import { useParams } from "react-router-dom";
import { getDataMapa } from "./integracion";
const apiMapKey = process.env.REACT_APP_MAPS_API_KEY
const Mapa = () => {
    const param = useParams()
    const [openModal,setOpenModal] = useState(false)
    const [trazo, setTrazo] = useState(null);
    const [dataModal, setDataModal] = useState(null);
    const [mapa, setMapa] = useState(null);
    const [modalData, setModalData] = useState(null);
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiMapKey,
        libraries: ['geometry', 'drawing'],
    });
    const mapStyles = {
        height: '80vh',
        width: '100%'
    };
    const defaultCenter = {
        lat: 20.67129241646564,
        lng: -103.35232101509537
    };
    useEffect(() => {
        // setTrazo(trazoJson);
        getDataMapa(param.idBitacora,setTrazo,setDataModal)
    }, []);
    
    // const clickMarker = (id) => {
    //     setOpenModal(true)
    //     setModalData({
    //         id:id,
    //         operador:trazo.markers[id].operador,
    //         personas:trazo.markers[id].personas,
    //         unidad:trazo.markers[id].unidad
    //     })
    // }
    console.log("dataModal",dataModal);
    return (
        <>
            {trazo ?
                isLoaded && <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={defaultCenter}
                    onLoad={map => setMapa(map)}
                >
                    {trazo.markers?.map((item, index) => {
                        return (
                            <div >
                                <MarkerF key={index} position={item.marker} 
                                // onClick={() => { clickMarker(index) }}
                                    options={{
                                        label: {
                                            text: `${index + 1}`, // Texto a mostrar en el marcador (puede ser un número o un texto)
                                            color: 'white', // Color del texto del marcador
                                            fontWeight: 'bold' // Estilo del texto del marcador
                                        }
                                    }}
                                >
                                </MarkerF>
                            </div>
                        )
                    })}
                    <PolylineF
                        path={trazo.trazo} // Define el conjunto de coordenadas para la polilínea
                        options={{
                            strokeColor: '#FF0000', // Color de la línea (en formato hexadecimal)
                            strokeWeight: 4, // Grosor de la línea
                        }}
                    />
                     {openModal?<><PersonasModal setOpen={setOpenModal} data={dataModal}></PersonasModal></>:null}
                     {!openModal?<><Boton onClick={()=>{setOpenModal(true)}} className = "botonModal">Mostrar informacion de la ruta</Boton></>:null}

                </GoogleMap>
                :
                null}
                {/* {openModal?<><ModalMonitorReporte setOpen={setOpenModal} data={modalData}/></>:null} */}
               
                
        </>
    )
}
export default Mapa