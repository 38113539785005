import React from "react";
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import './Dashboard.scss'

const Dashboard = () => {
    return (
        <div className="Dashboard">
            
        </div>
    )
}
export default Dashboard