import React, { useEffect, useState } from "react";
import './RecuperarContrasenia.scss'
import logo from '../../assets/img/logosuma.png'
import InputText from "../../components/atoms/InputText/InputText";
import Boton from "../../components/atoms/Boton/Boton";
import { useNavigate } from "react-router-dom";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
const RecuperarContrasenia = ()=>
{
    const navigate = useNavigate()
    const [inputLista,setInputLista] = useState({
        email:{value:null,validationType:"email"}
    })
    useEffect(()=>
    {
        for(const elemento in inputLista)
        {
            if(document.getElementById(elemento))
            {
                if(elemento == "email")
                {
                    ColorValidation(elemento,inputLista,"email")
                }
            }

        }
    },[inputLista])
    const siguientePaso = ()=>
    {
        if(SubmitValidation(inputLista,setInputLista))
        {
                navigate("/codigo-verificacion")
        }
        else{
            alert("campo incorrecto")
        }
            
    }
    return(
        <div className="RecuperarContrasenia">
            <div className="modal">
                <div className="contenedorLogo">
                    <img src={logo} alt="" width={"150px"}/>
                </div>
                <div className="descripcion">
                    <div className="titulo">Recuperar cuenta</div>
                    <div className="subTitulo">Actualiza tus accesos</div>
                </div>
                <InputText id="email" title="Correo electrónico" obligatorio placeholder={"Ingresa tu correo electrónico"} onChange={(e)=>{UpdateValue(e,"email",inputLista,setInputLista)}}></InputText>
                <div className="botonesFooter">
                    <Boton onClick={()=>{navigate("/")}} width="50%" background = "black">Regresar</Boton>
                    <Boton width="50%" onClick={()=>{siguientePaso()}} >Enviar codigo</Boton>
                </div>
            </div>
        </div>
    )
}
export default RecuperarContrasenia