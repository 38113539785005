import React, { useEffect, useState } from "react";
import './BotonesTabulares.scss'

const BotonesTabulares = ({ textoBoton1 = "", textoBoton2 = "", contenido1, contenido2 }) => {
    const [Seleccion, setSeleccion] = useState({
        contenido1: true,
        contenido2: false
    })
    const cambiarSeleccion = () => {

        const SeleccionCopia = { ...Seleccion }
        SeleccionCopia.contenido1 = !SeleccionCopia.contenido1
        SeleccionCopia.contenido2 = !SeleccionCopia.contenido2
        setSeleccion(SeleccionCopia)
    }

    return (
        <>
            <div className="BotonesTabulares">
                <button className={`izquierdo ${Seleccion.contenido1 ? "seleccionado" : ""}`} onClick={cambiarSeleccion}>{textoBoton1}</button>
                <button className={`derecho ${Seleccion.contenido2 ? "seleccionado" : ""}`} onClick={cambiarSeleccion}>{textoBoton2}</button>
            </div>
            <div className="contenidoSecciones">
                {
                    Seleccion.contenido1 ?
                        <>
                            {contenido1}
                        </> :
                        Seleccion.contenido2 ?
                            <>
                                {contenido2}
                            </> :
                            null
                }
            </div>
        </>
    )
}
export default BotonesTabulares