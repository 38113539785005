import React from "react";
import './PersonasModal.scss'
import volante from '../../../assets/icons/userOperator.svg'
import unidad from '../../../assets/img/unidad.png'
const PersonasModal = ({ setOpen, data }) => {
    return (
        <div className="PersonasModal">
            <div className="buttonClose">
                <button onClick={() => { setOpen(false) }}>x</button>
            </div>
            <div className="ruta">{data.ruta}</div>
            {/* <hr></hr> */}
            <div className="bodyPersonasModal">
                <div className="operador">
                    {/* icono operador */}
                    <img width={35} src={volante} alt="" />
                    <div className="nombre">{data.operador}</div>
                </div>
                <div className="operador">
                    {/* icono operador */}
                    <img width={30} src={unidad} alt="" />
                    <div className="nombre">U - {data.unidad}  /   Capacidad - {data.capacidad}</div>
                </div>

                <div className="ruta" style={{ marginTop: "10px" }}>PERSONAS :&nbsp;&nbsp;  <label style={{color:"green"}}>{data.personas?.length}</label></div>
                <hr />

                <div className="overflow">


                    {
                        data.personas?.map((item, key) => {
                            return (
                                <>
                                    <div className="personas mt-20">
                                        < div className="persona">
                                            {/* punto de persona */}

                                            <div className="punto"></div>
                                            <div>
                                                <div className="idPersona"><label>ID: </label>{item?.ID_PASAJERO}</div>
                                                <div className="coordenadas">
                                                    <div className="lat"><label>LAT: </label>{item?.LATITUD}</div>
                                                    <div className="lng"><label>LNG: </label>{item?.LONGITUD}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }



                </div>






            </div>

        </div>
    )
}
export default PersonasModal