import React from "react";
import './OpcionesUsiario.scss'
import EditarPerfil from "../EditarPerfilCompenente/EditarPerfilCompenente";
import Boton from "../../atoms/Boton/Boton";
import { useNavigate } from "react-router-dom";
import { encriptar } from "../../../utilidades/encriptacion";
const clave = process.env.REACT_APP_CLAVE_INCRIPTACION
const OpcionesUsiario = ({ setAuth,data, estado }) => {
   const navigate = useNavigate()
    const nombre = "Jorge Antonio Fuerte Diaz"
    const cerrarSecion = ()=>
    {
        setAuth(false)
        localStorage.setItem("auth",encriptar("false",clave))
        navigate("/")
    }
    return (
        <div className="OpcionesUsiario" >
            <div className="showAlers">
                <div className="hedaerNotifications">
                    <div>
                        <div className="saludo">Hola <span>{nombre}</span></div>
                        <div className="estadoOperador">
                            {
                                estado ?
                                    <>Disponible</> :
                                    <>Ocupado</>
                            }
                        </div>
                    </div>
                </div>
                <div className="bodyNotifications">
                    <EditarPerfil></EditarPerfil>
                    <div className="contenedorBoton">
                        <Boton onClick={()=>{cerrarSecion()}}>Cerrar sesión</Boton>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OpcionesUsiario