import React from "react";
import './EditarPerfilCompenente.scss'
import editarPerfil from '../../../assets/icons/editarPerfil.svg'
import { useNavigate } from "react-router-dom";
const EditarPerfil = () => {
    const navigate = useNavigate();
    return (
        <div className="EditarPerfilCompenente" onClick={()=>{navigate("/editar-usuario")}}>
            <div className="EditarPerfilContenido">
                <div className="icon">
                    <img src={editarPerfil} alt="" />
                </div>
                <div className="descripcion">
                    <div className="titulo">Editar Perfil</div>
                    <div className="subTitulo">Modifica tus datos personales</div>
                </div>
            </div>
        </div>
    )
}
export default EditarPerfil