import React, { useState } from "react";
import './BotonDescargarExel.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
const BotonDescargarExel = ({nombreArchivo,datos})=>
{
    const ExportarAexel = (nombreExel)=>
    {
        const hojaTrabajo = XLSX.utils.json_to_sheet(datos.data)
        const libroTrabajo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(libroTrabajo,hojaTrabajo,'Hoja1')
        XLSX.writeFile(libroTrabajo, `${nombreExel}.xlsx`)
    }
    return(
        <button className="BotonDescargarExel" onClick={()=>{ExportarAexel(nombreArchivo)}}> 
            <FontAwesomeIcon icon={faFileArrowDown} className="img"/>
            Descargar
        </button>
    )
}
export default BotonDescargarExel