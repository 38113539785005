import axios from "axios"
import Swal from "sweetalert2";
import { encriptar } from "../../utilidades/encriptacion.js";
const url = process.env.REACT_APP_URL_API
const clave = process.env.REACT_APP_CLAVE_INCRIPTACION
export const peticionLogin = (email,password,setAuth,navigate)=>
{
    axios.post(`${url}/aforo/login?email=${email}&password=${password}`).then((res)=>
    {
        Swal.fire({
            title: "SESIÓN INICIADA",
            icon:"success",
            timer: 2000,
            showConfirmButton: false,
          });
          setAuth(true)
        localStorage.setItem('auth', encriptar("true",clave));
        localStorage.setItem('dataClient', encriptar(JSON.stringify(res.data),clave));
        navigate("/dashboard/reporte")

    })
    .catch((error)=>
    {
        console.log("res",error);
    })

}
//http://tapi.sumaenlinea.mx/login?email=testclient@pmi.com&password=morris