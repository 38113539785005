import React, { Suspense } from "react";
import { Routes,Route } from "react-router-dom";
import Dashboard from "../../views/Dashboard/Dashboard";
import DashboardReportes from "../../views/DaboardReportes/DashboardReportes";
import DashboardReporteLlegadas from "../../views/DashboardReporteLlegadas/DashboardReporteLlegadas";
import AforosMonitorReporte from "../../views/AforosMonitorReporte/AforosMonitorReporte";
import AforosReporteMapa from "../../views/AforosReporteMapa/AforosReporteMapa";
import AforosMonitoreoEnVivo from "../../views/AforosMonitoreoEnVivo/AforosMonitoreoEnVivo";
import AforosMapaTiempoReal from "../../views/AforosMapaTiempoReal/AforosMapaTiempoReal";

export const DashboardRoutes = () =>
{
    return(
        <Routes>
            <Route exact path="/dashboard" element={
                <Suspense fallback={<>skeleton</>}>
                    <Dashboard></Dashboard>
                </Suspense>
            } />
            <Route exact path="/dashboard/reporte" element={
                <Suspense fallback={<>skeleton</>}>
                    <DashboardReportes/>
                </Suspense>
            } />
            <Route exact path="/dashboard/reporte-llegada" element={
                <Suspense fallback={<>skeleton</>}>
                    <DashboardReporteLlegadas/>
                </Suspense>
            } />


            
            <Route exact path="/dashboard/monitor-reporte" element={
                <Suspense fallback={<>skeleton</>}>
                    <AforosMonitorReporte/>
                </Suspense>
            } />
             <Route exact path="/dashboard/monitor-reporte/:id/:idBitacora/mapa" element={
                <Suspense fallback={<>skeleton</>}>
                    <AforosReporteMapa/>
                </Suspense>
            } />
            <Route exact path="/dashboard/monitoreo-vivo" element={
                <Suspense fallback={<>skeleton</>}>
                    <AforosMonitoreoEnVivo/>
                </Suspense>
            } />
            <Route exact path="/dashboard/monitoreo-vivo/:idBitacora/mapa" element={
                <Suspense fallback={<>skeleton</>}>
                   <AforosMapaTiempoReal/>
                </Suspense>
            } />
        </Routes>
    )
}
export default DashboardRoutes