import axios from "axios"

const urlApi = process.env.REACT_APP_URL_API
export const traerInfoUsuario = (setStatus,id_usuario)=>
{
    console.log("id_usuario",id_usuario);
    // axios.get(`${urlApi}/aforo/getUsuarios/id_usuario=${id_usuario}`)
    // .then((res)=>
    // {
    //     setStatus(res.data)
    // })
    // .catch((error)=>
    // {
    //     console.log(error);
    // })
}