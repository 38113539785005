import React, { useEffect, useState } from "react";
import './AforosQrCrear.scss'
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";

import { Dropdown } from "primereact/dropdown";
import Boton from "../../components/atoms/Boton/Boton";
import InputText from "../../components/atoms/InputText/InputText";

const AforosQrCrear = () => {
    const navigate = useNavigate()
    const [inputLista, setInputLista] = useState({
        idCliente: { value: 163, validationType: "empty" },
        idUsuario: { value: null, validationType: "empty" }
    })
    useEffect(() => {
        for (const Elemento in inputLista) {
            if (document.getElementById(Elemento)) {
                ColorValidation(Elemento, inputLista)
            }
        }
    }, [inputLista])
    const idClientes = [
        { name: '163 - PLEXUS', code: 163 },

    ];
    const guardarUsuario = () => {
        if (SubmitValidation(inputLista, setInputLista)) {
            Swal.fire({
                icon: "success",
                title: "Nuevo usuario guardado",
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/aforos/qr")
        }
    }
    return (
        <div className="AforosQrCrear">
            <DefaultCard>
                <div className="titulo">Agregar nuevo usuario</div>
                <hr />
                <div className="drop">
                    <div className="tituloDropd">ID cliente</div>
                    <Dropdown id="idCliente" value={inputLista.idCliente.value} onChange={(e) => { UpdateValue(e, "idCliente", inputLista, setInputLista) }} options={idClientes} optionLabel="name" placeholder="Selecciona el tipo de cliente" filter className="dropclass" />
                </div>
                <div className="IdUsuario">
                    <InputText placeholder={"Ingresa el id del usuario"} id="idUsuario" title="Id usuario" onChange={(e) => { UpdateValue(e, "idUsuario", inputLista, setInputLista) }}></InputText>
                </div>
                <div className="guardar">
                    <Boton onClick={() => { guardarUsuario() }}>Guardar usuario</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}
export default AforosQrCrear