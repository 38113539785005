import axios from "axios"

const urlApi = process.env.REACT_APP_URL_API
export const consultarUsuarios = async (setState)=>
{
    await axios.get(`${urlApi}/aforo/getUsuarios`)
    .then((res)=>
    {
        console.log(res);
        setState(res.data)
    })
    .catch((error)=>
    {
        console.log(error);
    })
    
}