import React, { useEffect, useState } from "react";
import './DashboardReportes.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import InputCalendario from "../../components/atoms/InputCalendario/InputCalendario";
import BotonLinea from "../../components/atoms/BotonLinea/BotonLinea";
import iconoBuscar from '../../assets/icons/lupa.svg'
import TarjetaReportes from "../../components/molecules/TarjetaReportes/TarjetaReportes";
import { faRoute, faBellConcierge, faClock, faExclamation, faTaxi, faComment, faCarBurst, faRobot, faUsers } from '@fortawesome/free-solid-svg-icons';
import TarjetaTabla from "../../components/molecules/TarjetaTabla/TarjetaTabla";

// import tarjetaDatos from '../../assets/json/reporte/reporteInformacionTarjetas.json'
import { getDashboardData, formatDateFromString } from "./integracion.js";
const DashboardReportes = () => {
    const [desdeFecha, setDesdeFecha] = useState(new Date)
    const [astaFecha, setAstaFecha] = useState(new Date)
    const [tarjetaDatos, setTarjetaDatos] = useState(null)
    const [skeleton, setSkeleton] = useState(true)
    useEffect(() => {
        getDashboardData(setSkeleton, formatDateFromString(desdeFecha), formatDateFromString(astaFecha), setTarjetaDatos)
    }, [])
    const buscar = () => {
        setSkeleton(true)
        getDashboardData(setSkeleton, formatDateFromString(desdeFecha), formatDateFromString(astaFecha), setTarjetaDatos)
    }
    console.log("tarjetaDatos",tarjetaDatos);

    return (
        <>
            <div className="DashboardReportes">
                <DefaultCard>
                    <div className="titulo">Filtro de busqueda</div>
                    <hr />
                    <div className="contenido">
                        <div className="izquierda">
                            <div className="contenedorInputsFecha">
                                <InputCalendario onChange={(e) => { setDesdeFecha(e.value) }} value={desdeFecha} obligatorio={false} titulo={"Desde:"}></InputCalendario>
                                <InputCalendario onChange={(e) => { setAstaFecha(e.value) }} value={astaFecha} obligatorio={false} titulo={"Hasta:"}></InputCalendario>
                            </div>
                        </div>
                        <div className="derecha">
                            <div className="contenedorBotonBuscar">
                                <BotonLinea width="100%" icono={iconoBuscar} onClick={() => { buscar() }}>buscar</BotonLinea>
                            </div>
                        </div>
                    </div>
                </DefaultCard>

                <div className="contenedorDeTarjetas">
                    {/* rutas   servicios   pasajeros */}
                    <div className="contenedorSeccion">
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.rutas} id={"rutas"} titulo={"Rutas"} icono={faRoute} iconoGraficaM color={"108, 230, 244"}>{tarjetaDatos?.rutas.length}</TarjetaReportes>
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.servicios} id={"Servicios"} titulo={"Servicios"} icono={faBellConcierge} iconoGraficaM color={"108, 230, 244"}>{tarjetaDatos?.servicios.length}</TarjetaReportes>
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.pasajeros} id={"Pasajeros"} titulo={"Pasajeros"} icono={faUsers} iconoGraficaM color={"108, 230, 244"}>{tarjetaDatos?.pasajeros.length}</TarjetaReportes>
                    </div>
                    {/* llegada en tiempo   llegada fuera de tiempo */}
                    <div className="contenedorSeccion">
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.llegadaAtiempo} id={"Llegadasentiempo"} titulo={"Llegadas: en tiempo"} icono={faClock} iconoGraficaM color={"39, 179, 69"}>{tarjetaDatos?.llegadaAtiempo.length}</TarjetaReportes>
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.llegadaFueraDetiempo} id={"Llegadasfueradetiempo"} titulo={"Llegadas: fuera de tiempo"} icono={faExclamation} iconoGraficaM color={"255, 211, 105"}>{tarjetaDatos?.llegadaFueraDetiempo.length}</TarjetaReportes>
                    </div>
                    {/* #taxis  $taxis  tipo de unidades */}
                    <div className="contenedorSeccion">
                        <TarjetaReportes disabled skeleton={skeleton} datos={tarjetaDatos?.ntaxis} width="200px" id={"Taxis1"} titulo={"#Taxis"} icono={faTaxi} iconoGraficaM={false} color={"255, 211, 105"}>{tarjetaDatos?.ntaxis.length}</TarjetaReportes>
                        <TarjetaReportes disabled skeleton={skeleton} datos={tarjetaDatos?.costostaxis} width="200px" id={"Taxis2"} titulo={"$Taxis"} icono={faTaxi} iconoGraficaM={false} color={"255, 211, 105"}>{tarjetaDatos?.costostaxis.length}</TarjetaReportes>
                        <TarjetaTabla skeleton={skeleton} width="490px" datos={tarjetaDatos?.tipoDeUnidades} titulo={"Tipo de unidades"}></TarjetaTabla>
                    </div>
                    {/* comentarios  sin concluir   #aforos     %aforos */}
                    <div className="contenedorSeccion">
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.comentarios} width="215px" id={"Comentarios"} titulo={"Comentarios"} icono={faComment} iconoGraficaM color={"160, 158, 158"}>{tarjetaDatos?.comentarios.length}</TarjetaReportes>
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.sinConcluir} width="215px" id={"Sinconcluir"} titulo={"Sin concluir"} icono={faCarBurst} iconoGraficaM color={"160, 158, 158"}>{tarjetaDatos?.sinConcluir.length}</TarjetaReportes>
                        <TarjetaReportes skeleton={skeleton} datos={tarjetaDatos?.numeroAforos} width="215px" id={"Aforos#"} titulo={"# Aforos"} icono={faRobot} iconoGraficaM color={"160, 158, 158"}>{tarjetaDatos?.numeroAforos.length}</TarjetaReportes>
                        {/* <TarjetaReportes datos={tarjetaDatos?.porcentajeAforos} disabled width="215px" id={"Aforos%"} titulo={"% Aforos"} icono={faRobot} iconoGraficaM color={"160, 158, 158"}>{tarjetaDatos?.porcentajeAforos.length}</TarjetaReportes> */}
                    </div>
                    {/* #incidente      %incidente      Trafico */}
                    <div className="contenedorSeccion">
                        {/* <TarjetaReportes datos={tarjetaDatos.numeroIncidente} id={"Incidente#"} titulo={"# Incidente"} icono={faRoute} iconoGraficaM color={"241, 67, 54"}>{tarjetaDatos.numeroIncidente.length}</TarjetaReportes>
                                <TarjetaReportes datos={tarjetaDatos.porcentajeIncidente} disabled id={"Incidente%"} titulo={"% Incidente"} icono={faBellConcierge} iconoGraficaM color={"241, 67, 54"}>{tarjetaDatos.porcentajeIncidente.length}</TarjetaReportes> */}
                    </div>

                </div>
            </div>
        </>


    )
}
export default DashboardReportes