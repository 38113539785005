import React from "react";
import './BotonEditar.scss'
import lapiz from '../../../assets/icons/lapiz.svg'
const BotonEditar = ({onClick})=>
{
    return(
        <div className="BotonEditar">
            <button onClick={onClick}><img width={"20px"} src={lapiz} alt="" /></button>
        </div>
    )
}
export default BotonEditar