import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import EditarPerfil from "../../views/EditarPerfil/EditarPerfil";

const UsuarioRutas = () => {
    return (
        <Routes>
            <Route exact path="/editar-usuario" element={
                <Suspense fallback={<>skeleton</>}>
                    <EditarPerfil></EditarPerfil>
                </Suspense>} />
        </Routes>
    )
}
export default UsuarioRutas