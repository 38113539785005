import React, { useState } from "react";
import './ModalReporte.scss'
import BotonDescargarExel from "../../atoms/BotonDescargarExel/BotonDescargarExel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ModalReporte = ({ setStatus, status, datos }) => {
    return (
        <>

            <div className="ModalReporteFondo">
                <div className="ModalReporte">
                    <div className="headerModal">
                        <div className="titulo">{datos?datos?.titulo:"No se encuentran registros"}</div>
                        <button className="botonx" onClick={() => { setStatus(!status) }}>X</button>
                    </div>
                    <hr />
                    <div className="descargar">
                        <BotonDescargarExel nombreArchivo={datos?.titulo} datos={datos}></BotonDescargarExel>
                    </div>
                    <div className="tabla">
                        <DataTable value={datos?.data} tableStyle={{ minWidth: '50rem' }}>
                            {
                                datos?.columnas.map((col, key) => {
                                    return (
                                        <Column key={key} field={col.field} header={col.header} />
                                    )
                                })
                            }
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModalReporte