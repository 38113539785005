import React, { useEffect, useState } from "react";
import './AforosEtiquetasEditar.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import { Dropdown } from "primereact/dropdown";
import Boton from "../../components/atoms/Boton/Boton";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import { useNavigate, useParams } from "react-router-dom";
import EstadoActivoInactivo from "../../components/atoms/EstadoActivoInactivo/EstadoActivoInactivo";
import Swal from "sweetalert2";

const AforosEtiquetasEditar = () => {
    const navigate = useNavigate()
    const [estatus, setEstatus] = useState(false)
    const params = useParams()
    const submit = () => {
        Swal.fire({
            icon: "success",
            title: "Empleado actualizado",
            showConfirmButton: false,
            timer: 1500
        });
        navigate("/aforos/qr")
    }
    return (
        <div className="AforosEtiquetasEditar">
            <DefaultCard>
                <div className="drop">
                    <div className="tituloDropd"> Usuario : {params.id}</div>
                    {/* <Dropdown id="idUsuario" value={inputLista.idUsuario.value} onChange={(e) => { UpdateValue(e, "idUsuario", inputLista, setInputLista) }} options={usuarios} optionLabel="name" placeholder="Selecciona el aforo disponible" filter className="dropclass" /> */}
                </div>
                <div className="estatusContenedor">
                    <EstadoActivoInactivo onClick={() => { setEstatus(!estatus) }} value={estatus}></EstadoActivoInactivo>
                </div>
                <div className="botones">
                    <Boton onClick={() => { submit() }}>Actualizar</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}
export default AforosEtiquetasEditar