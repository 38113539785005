import React, { useEffect, useState } from "react";
import './AforosMonitorReporte.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import InputCalendario from "../../components/atoms/InputCalendario/InputCalendario";
import InputRadio from "../../components/atoms/InputRadio/InputRadio";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import { Dropdown } from "primereact/dropdown";
import iconoBuscar from '../../assets/icons/lupa.svg'
import BotonLinea from "../../components/atoms/BotonLinea/BotonLinea";
import { useNavigate } from "react-router-dom";
import { getRoutes, getSelectTurnos } from "./integracion";
import { desEncriptar } from "../../utilidades/encriptacion";
const AforosMonitorReporte = () => {
    const navigate = useNavigate()
    const [turnos, setTurnos] = useState(null)
    const [listaDeRutas, setListaDeRutas] = useState(false)
    const [inputLista, setInputLista] = useState({
        tipoTurno: { value: "Todos", validationType: "empty" },
        fecha: { value: null, validationType: "empty" },
        turno: { value: null, validationType: "empty" },
    })
    useEffect(() => {
        for (const nombrePropiedad in inputLista) {
            if (document.getElementById(nombrePropiedad)) {
                ColorValidation(nombrePropiedad, inputLista, "empty")
            }
        }
    }, [inputLista])
    const cambiarTurno = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.tipoTurno.value = e.value
        setInputLista(copiaLista)
    }
    // const turnos = [
    //     { name: 'EA - ENTRADA ADM SENCILLO ', code: '1' },
    //     { name: 'E1 - ENTRADA T1 MEDIO ', code: '2' },
    //     { name: 'E2 - ENTRADA T2 MEDIO', code: '3' },
    //     { name: 'EA2', code: '4' },
    //     { name: 'SA - SALIDA ADM SENCILLO', code: '5' },
    //     { name: 'S1 - SALIDA T1 MEDIO', code: '6' },
    //     { name: 'S2 - SALIDA T2 MEDIO', code: '7' },
    //     { name: 'S3 SALIDA T3 MEDIO', code: '8' },
    //     { name: 'SA2', code: '9' },

    // ];
    useEffect(() => {
        getSelectTurnos(setTurnos)
    }, [])
    const submit = () => {
        if (SubmitValidation(inputLista, setInputLista)) {
            var año = inputLista.fecha.value.getFullYear();
            var mes = ('0' + (inputLista.fecha.value.getMonth() + 1)).slice(-2); // Se suma 1 porque los meses comienzan desde 0
            var dia = ('0' + inputLista.fecha.value.getDate()).slice(-2);
            var fechaFormateada = año + '-' + mes + '-' + dia;
            var cliente = desEncriptar(localStorage.getItem("dataClient"), "MElj9kglRc")
            cliente = JSON.parse(cliente)
            getRoutes(fechaFormateada, inputLista.turno.value.code, cliente.data[0].ID_EMPRESA, setListaDeRutas)

        }
    }
    return (
        <div className="AforosMonitorReporte">
            <DefaultCard>
                <div className="titulo">Filtro de busqueda</div>
                <hr />
                <div className="contenido">
                    <InputCalendario id="fecha" titulo={"Fecha"} onChange={(e) => { UpdateValue(e, "fecha", inputLista, setInputLista) }}></InputCalendario>
                    {/* <div className="radioContenedor">
                        <div className="tituloRadios">Tipo Turno</div>
                        <div className="radios">
                            <InputRadio id="tipoTurno" checked={inputLista.tipoTurno.value == "Entradas" ? true : false} name={"turno"} title="Entradas" value={"Entradas"} onChange={(e) => { cambiarTurno(e) }}></InputRadio>
                            <InputRadio id="tipoTurno" checked={inputLista.tipoTurno.value == "Salidas" ? true : false} name={"turno"} title="Salidas" value={"Salidas"} onChange={(e) => { cambiarTurno(e) }}></InputRadio>
                            <InputRadio id="tipoTurno" checked={inputLista.tipoTurno.value == "Todos" ? true : false} name={"turno"} title="Todos" value={"Todos"} onChange={(e) => { cambiarTurno(e) }}></InputRadio>
                        </div>
                    </div> */}
                    <div className="drop">
                        <div className="tituloDropd">Turno:</div>
                        <Dropdown id="turno" value={inputLista.turno.value} onChange={(e) => { UpdateValue(e, "turno", inputLista, setInputLista) }} options={turnos} optionLabel="name" placeholder="Selecciona un turno" filter className="dropclass" />
                    </div>
                </div>
                <div className="botonContenedor">
                    <BotonLinea width="300px" icono={iconoBuscar} onClick={() => { submit() }}>buscar</BotonLinea>
                </div>
            </DefaultCard>
            {
                listaDeRutas ?
                    <>
                        <div className="dividir"></div>
                        <DefaultCard>
                            <div className="titulo">Filtro de busqueda</div>
                            <hr />
                            <div className="contenedorRutas">
                                {
                                    listaDeRutas.data ?
                                        <>
                                            {
                                                listaDeRutas.data.map((item, key) => {
                                                    return (
                                                        <>
                                                            <div className="ruta" onClick={() => { navigate(`/dashboard/monitor-reporte/${item.ID_RUTA}/${item.ID_BITACORA}/mapa`) }}>{item.RUTA}</div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </> :
                                        <>
                                            <div  >{"No se encontraron rutas"}</div>

                                        </>


                                }
                                {/* <div className="ruta" onClick={() => { navigate("/dashboard/monitor-reporte/2/mapa") }}>PLEXUS - ADMINISTRATIVO LAZARO CARDENAS (CONSOLIDADA)</div>
                                <div className="ruta" onClick={() => { navigate("/dashboard/monitor-reporte/3/mapa") }}>PLEXUS - ADMINISTRATIVO LAZARO CARDENAS (CONSOLIDADA)</div> */}
                            </div>
                        </DefaultCard>
                    </> : null
            }
        </div>
    )
}
export default AforosMonitorReporte