export const formatearRabla = (data,setData)=>
{
   let formato = {}
    formato.Autobus = data[0].CANTIDAD
    formato.Automovil = data[1].CANTIDAD
    formato.CamionChico = data[2].CANTIDAD
    formato.CamionGrande = data[3].CANTIDAD
    formato.Camioneta = data[4].CANTIDAD
    formato.Minivan = data[5].CANTIDAD
    formato.Sprinter = data[6].CANTIDAD
    let datosFinales =[]
    datosFinales.push(formato)
    
    setData(datosFinales)
}