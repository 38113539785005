
import './MenuOption.scss'
import sideMenu from '../../../assets/json/sideMenu.json'
import { useLocation, useNavigate } from "react-router-dom";
import arrowDown from '../../../assets/icons/sideMenu/arrow.svg'
import { useEffect, useState } from 'react';

const MenuOption = ({ menuData, index }) => {

    const location = useLocation()
    const navigate = useNavigate()

    const rute = location.pathname.split("/")[1]
    const subrute = location.pathname.split("/")[2]
    const routejson = menuData.url.split("/")[1]


    //cambia de estado la opcion si open del json del menu a !isOpen cada que se preciona
    const [mostrarSubMenu, setMostrarSubMenu] = useState()
    const showSubMenu = () => {
        setMostrarSubMenu(!mostrarSubMenu)
    }
    useEffect(() => {
        let rutaEncontrada = false
        sideMenu.map((item, key) => {
            if (index == key) {

                item.subMenu.map((itemSub, key) => {
                    if (location.pathname == itemSub.url) {
                        rutaEncontrada = true;
                    }
                })
            }

        })
        if (rutaEncontrada) {
            setMostrarSubMenu(true)
        }
        else {
            setMostrarSubMenu(false)
        }
    }, [rute])




    return (
        <>
            {/* optcion del menu principal */}
            <div id={"orageBorder"} className={`${mostrarSubMenu&&!menuData.isOpen?"noSeleccionado":""} ${index == 0 ? "firstOption" : ""} ${rute == routejson ? menuData.isOpen ? "orangeBackgroundRounded" : "orangeBackground" : "containerOptionMenu"}`}>
                <div className="optionMenuIcon" >
                    <img width={"25px"} src={require(`../../../assets/icons/sideMenu/${menuData.icon}`)}></img>
                </div>
                <div className="optionMenuTitle" onClick={() => { navigate(menuData.url) }}>{menuData.title}</div>
                {
                    !mostrarSubMenu ?
                        <div className="optionMenuArrow" onClick={showSubMenu}>
                            <img src={arrowDown} alt="optionMenuArrow" />
                        </div> :
                        <div className="optionMenuArrow" onClick={showSubMenu}>
                            <img src={arrowDown} className="upArrow" alt="optionMenuArrow" />
                        </div>

                }

            </div>
            {/* opcion del menu secundario */}
            {/* con esto : subrute == item.url.split("/")[2] comparamos
             si la url del navegador coincide con la url del json si es asi
            pinta de color anaranjado el texto si no lo deja igual */}
            <div className="showSubMenuTransicion">
                {
                    mostrarSubMenu ?
                        menuData.subMenu.map((item, key) => {

                            return (
                                <div className={`${"containerOptionMenu"} bggray`} onClick={() => { navigate(item.url) }}>
                                    <div className={`optionMenuIcon ${subrute == item.url.split("/")[2] ? "selectedSub" : ""}`}><img width={20} src={require(`../../../assets/icons/${item.icon}`)}></img></div>
                                    <div className={`optionMenuTitle ${subrute == item.url.split("/")[2] ? "selectedSub" : ""}`}>{item.title}</div>
                                </div>
                            )
                        })

                        :
                        null
                }
            </div>

        </>
    )
}
export default MenuOption