import React, { useEffect, useState } from "react";
import './ReingresarContrasenia.scss'
import InputText from "../../components/atoms/InputText/InputText";
import Boton from "../../components/atoms/Boton/Boton";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/img/logosuma.png'
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";

const ReingresarContrasenia = ({setAuth})=>
{
    const navigate = useNavigate()
    const [inputLista,setInputLista] = useState({
        password:{value:null,validationType:"empty"},
        confirmarPassword:{value:null,validationType:"empty"}
    })

    useEffect(()=>
    {
        for(const element in inputLista)
        {
            if(document.getElementById(element))
            {
                ColorValidation(element,inputLista)
            }
        }
    },[inputLista])
    const ingresar = ()=>
    {
        if(SubmitValidation(inputLista,setInputLista))
        {
            if(inputLista.password.value == inputLista.confirmarPassword.value)
            {
                setAuth(true)
                navigate("/dashboard/reporte")
            }
            else
            {
                alert("las contraseñas no coinciden")

            }
        }
    }
    return(
        <div className="ReingresarContrasenia">
            <div className="modal">
            <div className="contenedorLogo">
                    <img src={logo} alt="" width={"150px"}/>
                </div>
                <div className="descripcion">
                    <div className="titulo">Recuperar cuenta</div>
                    <div className="subTitulo">Ingresa el codigo que se envio a tu correo</div>
                </div>
                <div className="inputs">
                    <InputText id="password" type="password" obligatorio title="Nueva contraseña" onChange={(e)=>{UpdateValue(e,"password",inputLista,setInputLista)}}></InputText>
                    <InputText id="confirmarPassword" type="password" obligatorio title="Confirma tu contraseña" onChange={(e)=>{UpdateValue(e,"confirmarPassword",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="botonesFooter">
                    <Boton onClick={()=>{navigate("/recuperar-contraseña")}} width="50%" background = "black">Regresar</Boton>
                    <Boton width="50%" onClick={()=>{ingresar()}} >Ingresar</Boton>
                </div>
            </div>
        </div>
    )
}
export default ReingresarContrasenia