import React, { useEffect, useState } from "react";
import './MapaEnVivo.scss'
import { GoogleMap, LoadScript, MarkerF, OverlayViewF, Polyline, PolylineF, useJsApiLoader } from '@react-google-maps/api';

import PersonasModal from "../../molecules/PersonasModal/PersonasModal";
import Boton from "../../atoms/Boton/Boton";
import { useParams } from "react-router-dom";
import { getDataMapa, posicionUnidad } from "./integracion";
import markerImage from '../../../assets/img/iconoBus.png'
const apiMapKey = process.env.REACT_APP_MAPS_API_KEY
const MapaEnVivo = () => {
    const param = useParams()
    const [openModal,setOpenModal] = useState(false)
    const [trazo, setTrazo] = useState(null);
    const [dataModal, setDataModal] = useState(null);
    const [mapa, setMapa] = useState(null);
    const [posicionCamion,setPosicionCamion] = useState(null);
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiMapKey,
        libraries: ['geometry', 'drawing'],
    });
    const mapStyles = {
        height: '80vh',
        width: '100%'
    };
    const defaultCenter = {
        lat: 20.67129241646564,
        lng: -103.35232101509537
    };
    useEffect(() => {
        // setTrazo(trazoJson);
        getDataMapa(param.idBitacora,setTrazo,setDataModal)
    }, []);
    
    //tiempo real actualizacion de gps
    useEffect(()=>
    {
        posicionUnidad(`suma${dataModal?.unidad}`,setData);
    },[dataModal])
    const [data, setData] = useState(null);
    console.log("data", data);
    useEffect(() => {

        // Consulta cada 5 segundos (ejemplo)
        const interval = setInterval(() => {
            // posicionUnidad(`suma${dataModal?.idRuta}`,setData);
            getDataMapa(param.idBitacora,setTrazo,setDataModal)
        }, 10000); // 10000 milisegundos = 10 segundos

        return () => {
            clearInterval(interval);
        };
    }, []);
    console.log("dataModal",dataModal);
    return (
        <>
            {trazo ?
                isLoaded && <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={data?{ lat: data[0].max_lat, lng: data[0].max_lon }:defaultCenter}
                    onLoad={map => setMapa(map)}
                >
                    {trazo.markers?.map((item, index) => {
                        return (
                            <div >
                                <MarkerF key={index} position={item.marker} 
                                // onClick={() => { clickMarker(index) }}
                                    options={{
                                        label: {
                                            text: `${index + 1}`, // Texto a mostrar en el marcador (puede ser un número o un texto)
                                            color: 'white', // Color del texto del marcador
                                            fontWeight: 'bold' // Estilo del texto del marcador
                                        }
                                    }}
                                >
                                </MarkerF>
                            </div>
                        )
                    })}
                    {
                        data ?
                            <MarkerF 
                            position={{ lat: data[0]?.max_lat, lng: data[0]?.max_lon }} 
                            icon={
                                {
                                    url:markerImage,
                                    scaledSize: new window.google.maps.Size(40, 40), // Ajusta el tamaño según tus necesidades
                                }
                                }
                            
                            options={{
                                    label: {
                                        color: 'white', // Color del texto del marcador
                                        fontWeight: 'bold' // Estilo del texto del marcador
                                    }
                                }}
                            >
                            </MarkerF> : ""
                    }
                    <PolylineF
                        path={trazo.trazo} // Define el conjunto de coordenadas para la polilínea
                        options={{
                            strokeColor: '#FF0000', // Color de la línea (en formato hexadecimal)
                            strokeWeight: 4, // Grosor de la línea
                        }}
                    />
                     {openModal?<><PersonasModal setOpen={setOpenModal} data={dataModal}></PersonasModal></>:null}
                     {!openModal?<><Boton onClick={()=>{setOpenModal(true)}} className = "botonModal">Mostrar informacion de la ruta</Boton></>:null}

                </GoogleMap>
                :
                null}
                {/* {openModal?<><ModalMonitorReporte setOpen={setOpenModal} data={modalData}/></>:null} */}
               
                
        </>
    )
}
export default MapaEnVivo