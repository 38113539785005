import React, { useEffect, useState } from "react";
import './AforosMonitoreoEnVivo.scss'
import MapaEnVivo from "../../components/organisms/MapaEnVivo/MapaEnVivo";
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import listaRutas from '../../assets/json/aforos/listaRutasMonitoreoEnVivo.json'
import { useNavigate } from "react-router-dom";
import { getRoutes } from "./integracion";
import { desEncriptar } from "../../utilidades/encriptacion";
const AforosMonitoreoEnVivo = () => {
    const navigate = useNavigate()
    const [listaRutasE, setListaRutasE] = useState()
    useEffect(() => {
        const keyDecript = process.env.REACT_APP_CLAVE_INCRIPTACION
        const dataClient = JSON.parse(desEncriptar(localStorage.getItem("dataClient"), keyDecript))

        console.log("dataClient", dataClient);
        getRoutes(dataClient.data[0].ID_EMPRESA, setListaRutasE)
    }, [])
    const clickEnRuta = (id, confirmada) => {
        console.log("confirmada",confirmada);
        if (confirmada == 1) {
            navigate(`/dashboard/monitoreo-vivo/${id}/mapa`)
        }
    }
    return (
        <div className="AforosMonitoreoEnVivo">
            <DefaultCard>
                <div className="titulo">Lista de rutas en circulacion en vivo</div>
                <hr />
                <div className="ContenedorListaRutas">
                    {
                        listaRutasE ?
                            <>
                                {
                                    listaRutasE.map((item, key) => {
                                        return (
                                            <>
                                                <div className="listaRutas" onClick={() => { clickEnRuta(item.id,item.confirmada) }}>
                                                    {item.ruta} / {item.confirmada == 1 ?
                                                        <div className="containerBolas">
                                                            <div className="bolaVerde" />
                                                        </div>
                                                        :
                                                        <div className="containerBolas">
                                                            <div className="bolaRoja" />
                                                        </div>}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </> :
                            <>
                                <div className="sinRutas">Por el momento no hay rutas ACTIVAS</div>
                            </>

                    }
                </div>
            </DefaultCard>
        </div>
    )
}
export default AforosMonitoreoEnVivo