import React from "react";
import './InputCalendario.scss'
import { Calendar } from 'primereact/calendar';

const InputCalendario = ({ titulo, value, onChange, obligatorio = true,id }) => {
    return (
        <div className="InputCalendario">
            <div className="tituloContenedor">
                <div className="titulo">{titulo}</div>
                {
                    obligatorio ?
                        <>
                            <div className="obligatorio">*</div>
                        </> :null
                }
            </div>
            <Calendar id={id} className="calendario" value={value} onChange={onChange} dateFormat="dd/mm/yy" showIcon
                pt={{
                    input: {
                        root: { className: 'border-teal-500' }
                    },
                    dropdownButton: {
                        root: { className: 'colorNaranja' }
                    }
                }}
            />
        </div>
    )
}
export default InputCalendario