import React from "react";
import './BotonEliminar.scss'
import boteBaura from '../../../assets/icons/basura.svg'
const BotonEliminar = ()=>
{
    return(
        <div className="BotonEliminar">
            <button><img width={"20px"} src={boteBaura} alt="" /></button>
        </div>
    )
}
export default BotonEliminar