import React from "react";
import MapaEnVivo from "../../components/organisms/MapaEnVivo/MapaEnVivo";

const AforosMapaTiempoReal = ()=>
{
    return(
        <div className="AforosMapaTiempoReal">
            <MapaEnVivo></MapaEnVivo>
        </div>
    )
}
export default AforosMapaTiempoReal