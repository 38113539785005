import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AforosDispositivosEditar from "../../views/AforosDispositivosEditar/AforosDispositivosEditar";
import AforosEtiquetas from "../../views/AforosEtiquetas/AforosEtiquetas";
import AforosEtiquetasEditar from "../../views/AforosEtiquetasEditar/AforosEtiquetasEditar";
import AforosMonitorReporte from "../../views/AforosMonitorReporte/AforosMonitorReporte";
import AforosReporteMapa from "../../views/AforosReporteMapa/AforosReporteMapa";
import AforosMonitoreoEnVivo from "../../views/AforosMonitoreoEnVivo/AforosMonitoreoEnVivo";
import AforosMapaTiempoReal from "../../views/AforosMapaTiempoReal/AforosMapaTiempoReal";
import AforosQrCrear from "../../views/AforosQrCrear/AforosQrCrear";



const AforosRouters = () => {
    return (
        <Routes>
            <Route exact path="/aforos" element={
                <Suspense fallback={<>skeleton</>}>
                    <div>aforos</div>
                </Suspense>
            } />
            <Route exact path="/aforos/qr" element={
                <Suspense fallback={<>skeleton</>}>
                   <AforosEtiquetas/>
                </Suspense>
            } />
            <Route exact path="/aforos/qr/crear" element={
                <Suspense fallback={<>skeleton</>}>
                   <AforosQrCrear></AforosQrCrear>
                </Suspense>
            } />
            <Route exact path="/aforos/qr/:id/editar" element={
                <Suspense fallback={<>skeleton</>}>
                   <AforosEtiquetasEditar/>
                </Suspense>
            } />
            
        </Routes>
    )
}
export default AforosRouters