import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../views/Login/Login";
import RecuperarContrasenia from "../../views/RecuperarContrasenia/RecuperarContrasenia";
import CodigoVerificacion from "../../views/CodigoVerificacion/CodigoVerificacion";
import ReingresarContrasenia from "../../views/ReingresarContrasenia/ReingresarContrasenia";

const LoginRoutes = ({setAuth})=>
{
    return(
        <Routes>
            <Route exact path="/" element={
                <Suspense fallback={<>skeleton</>}>
                    <Login setAuth={setAuth}></Login>
                </Suspense>
            } />
            <Route exact path="/recuperar-contraseña" element={
                <Suspense fallback={<>skeleton</>}>
                    <RecuperarContrasenia/>
                </Suspense>
            } />
             <Route exact path="/codigo-verificacion" element={
                <Suspense fallback={<>skeleton</>}>
                    <CodigoVerificacion/>
                </Suspense>
            } />
             <Route exact path="/reigresar-contrasenia" element={
                <Suspense fallback={<>skeleton</>}>
                    <ReingresarContrasenia setAuth={setAuth}/>
                </Suspense>
            } />
        </Routes>
    )
}
export default LoginRoutes