import axios from "axios"
const url = process.env.REACT_APP_URL_API
export const getDashboardData = async(setSkeleton,desde,asta,setData)=>
{
    await axios.get(`${url}/portal/dashboard?idEmpresa=169&fecha_inicio=${desde}&fecha_fin=${asta}`).then((res)=>
    {
    //esta PARTE es para darle el formato correcto a la tabla de rutas ya que los campos de field - header. deben 
  //corresponder con la el campo data ejemplo:
  // field : id, headers:id
  //data[{id:1}]
        let newRes = res.data
        newRes.rutas.columnas[0].field = "ID"
        newRes.rutas.columnas[0].header = "ID"
        newRes.rutas.columnas[1].field = "NOMBRE"
        newRes.rutas.columnas[1].header = "RUTA"
        setSkeleton(false)
        setData(newRes)
    })
}
export const formatDateFromString =(dateString)=> {
    const fecha = new Date(dateString);
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Sumar 1 al mes ya que los meses van de 0 a 11
    const day = String(fecha.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

 
 