import axios from "axios"
const urlApi = process.env.REACT_APP_URL_API
export const getSelectTurnos = (setTurnos)=>
{
    axios.get(`${urlApi}/aforo/getTurnos`)
    .then((res)=>
    {
        setTurnos(res.data.data)
    })
    .catch((error)=>
    {
        console.log("error",error);
    })
}

export const getRoutes = (fecha,idTurno,idCliente,setRutas)=>
{
   
    axios.get(`${urlApi}/aforo/getRutasBitacoras?turno=${idTurno}&fecha=${fecha}&id_cliente=${idCliente}`)
    .then((res)=>
    {
        console.log("res",res);
        setRutas(res.data)
    })
    .catch((error)=>
    {
        console.log(error);
    })
}