import React, { useEffect, useState } from "react";
import './DashboardReporteLlegadas.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import InputCalendario from "../../components/atoms/InputCalendario/InputCalendario";
import BotonLinea from "../../components/atoms/BotonLinea/BotonLinea";
import iconoBuscar from '../../assets/icons/lupa.svg'
import BotonDescargarExel from "../../components/atoms/BotonDescargarExel/BotonDescargarExel";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import dataTable from '../../assets/json/reporte/dataTableDashboardReporteLLegada.json'
import { getList } from "./integracion";
const DashboardReporteLlegadas = () => {
    const [desdeFecha, setDesdeFecha] = useState(new Date)
    const [astaFecha, setAstaFecha] = useState(new Date)
    const [tipo, setTipo] = useState(null);
    const [turno, setTurno] = useState(null);
    const [turnos, setTurnos] = useState(null);
   
    // const turnos = [
    //     { name: 'Todos', code: 'Todos' },
    //     { name: 'EA - Entrada Adm sencillo', code: 'EA' },
    //     { name: 'Entrada T1 medio', code: 'T1' },
    //     { name: 'E2 - Entrada T2 medio', code: 'T2' },
    //     { name: 'E3 - Entrada T3 medio', code: 'T3' },
    //     { name: 'EA2 - Entrada Adm 2', code: 'EA2' },
    // ];
    useEffect(()=>
    {
        getList(setTurnos)
    },[])
    const buscar = ()=>
    {
        var desdeaño =  desdeFecha.getFullYear();
        var desdemes = ('0' + desdeFecha.getMonth() + 1).slice(-2); // Se suma 1 porque los meses comienzan desde 0
        var desdedia = ('0' +  desdeFecha.getDate()).slice(-2);
        var desdefechaFormateada = desdeaño + '-' + desdemes + '-' + desdedia;
        var astaaño =  astaFecha.getFullYear();
        var astames = ('0' + astaFecha.getMonth() + 1).slice(-2); // Se suma 1 porque los meses comienzan desde 0
        var astadia = ('0' +  astaFecha.getDate()).slice(-2);
        var astafechaFormateada = astaaño + '-' + astames + '-' + astadia;

        
        console.log("desdefechaFormateada",desdefechaFormateada);
        console.log("astafechaFormateada",astafechaFormateada);
    }
  
    return (
        <div className="DashboardReporteLlegadas">
            {/* filtro */}
            <DefaultCard>
                <div className="titulo">Filtro de busqueda</div>
                <hr />
                <div className="contenido">
                    <div className="izquierda">
                        <div className="contenedorInputsFecha">
                            <InputCalendario onChange={(e) => { setDesdeFecha(e.value) }} value={desdeFecha} obligatorio={false} titulo={"Desde:"}></InputCalendario>
                            <InputCalendario onChange={(e) => { setAstaFecha(e.value) }} value={astaFecha} obligatorio={false} titulo={"Hasta:"}></InputCalendario>
                        </div>
                    </div>
                    <div className="derecha">
                        <div className="contenedorBotonBuscar">
                            <BotonLinea width="100%" icono={iconoBuscar} onClick={buscar}>buscar</BotonLinea>
                            <BotonDescargarExel datos={dataTable} nombreArchivo={`Reporte llegada de ${desdeFecha.toLocaleDateString()} a ${astaFecha.toLocaleDateString()}`}></BotonDescargarExel>
                        </div>
                    </div>
                    <div className="containerDropdown">
                        {/* <div className="drop">
                            <div className="tituloDropd">Tipo:</div>
                            <Dropdown value={tipo} onChange={(e) => setTipo(e.value)} options={tipos} optionLabel="name" placeholder="Selecciona el tipo" filter className="dropclass" />
                        </div> */}
                        <div className="drop">
                            <div className="tituloDropd">Turno:</div>
                            <Dropdown value={turno} onChange={(e) => setTurno(e.value)} options={turnos} optionLabel="name" placeholder="Selecciona el turno" filter className="dropclass" />
                        </div>
                    </div>

                </div>
            </DefaultCard>
            <div className="separador"></div>
            {/* datatable */}
            <DefaultCard>
                <DataTable value={dataTable.data} tableStyle={{ minWidth: '70rem',fontSize:"14px" }} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                    {dataTable.header.map((col, i) => (
                        <Column key={col.field} field={col.field} header={col.header} />
                    ))}
                </DataTable>
            </DefaultCard>
        </div>
    )
}
export default DashboardReporteLlegadas