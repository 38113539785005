import React, { useEffect, useState } from "react";
import './EditarPerfil.scss'
import BotonesTabulares from "../../components/molecules/BotonesTabulares/BotonesTabulares";
import { InformacionPersonal } from "./InformacionPersonal";
import CambiarContraseña from "./CambiarContraseña";


const EditarPerfil = ()=>
{
    
    
    return(
        <div className="EditarPerfil">
                <BotonesTabulares textoBoton1="Información personal" textoBoton2="Cambiar la contraseña" contenido1={<InformacionPersonal/>} contenido2={<CambiarContraseña/>}/>
        </div>
    )
}
export default EditarPerfil