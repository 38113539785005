import axios from "axios";

const URL_api = process.env.REACT_APP_URL_API

export const getDataMapa = async (idBitacora, setData,setDataModal) => {

    await axios.get(`${URL_api}/aforo/getTrazo?id_bitacora=${idBitacora}`)
        .then((res) => {
            let markers = []
            let trazo = []
            console.log("res", res);
            // extraemos los markers
            for (let i = 0; i < res.data.data.TRAZO.features.length - 1; i++) {
                markers.push({
                    marker:
                    {
                        lat: res.data.data.TRAZO.features[i].geometry.coordinates[1],
                        lng: res.data.data.TRAZO.features[i].geometry.coordinates[0]
                    }
                })
            }
            // extraemos el trazo
            res.data.data.TRAZO.features[res.data.data.TRAZO.features.length - 1].geometry.coordinates.map((item, key) => {

                trazo.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
            //datos para el modal de informacion
            let dartaModal = 
            {
                ruta:res.data.data.NOMBRE_RUTA,
                operador:res.data.data.CHOFER,
                unidad:res.data.data.ID_UNIDAD,
                capacidad:res.data.data.CAPACIDAD,
                personas:res.data.data.PASAJEROS

            }
            //seteo final de los datos
            setDataModal(dartaModal)
            setData({ markers: markers, trazo: trazo })
        })
        .catch((error) => {
            console.log("error", error);
        })
}
