import React, { useEffect, useState } from "react";
import './TarjetaReportes.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import ModalReporte from "../ModalReporte/ModalReporte";
import { Skeleton } from "primereact/skeleton";


const TarjetaReportes = ({ id, titulo, icono, iconoGraficaM, color, children, width, datos, disabled = false, skeleton=true }) => {
    const [mostrarModal, setMostrarModal] = useState(false)
    useEffect(() => {
        if(!skeleton)
        {
            const tarjetaHover = document.getElementById(id)
            const iconoGrafica = document.getElementById(`iconoGrafica${id}`)
            tarjetaHover.addEventListener("mouseover", () => {
                tarjetaHover.style.background = `rgb(${color})`
                iconoGrafica.style.color = "white"
                iconoGrafica.style.transition = "all 0.2s"
            })
            tarjetaHover.addEventListener("mouseout", () => {
                tarjetaHover.style.background = `white`
                iconoGrafica.style.color = `rgb(${color})`
                iconoGrafica.style.transition = "all 0.2s"
            })
        }

    }, [datos])

    const eventoClick = ()=>
    {
        if(!skeleton)
        {
            setMostrarModal(!mostrarModal)
        }
    }
    return (
        <>
            <div id={id} className="TarjetaReportes" style={{ width: width }} onClick={() => {eventoClick()}}>
                {
                    skeleton ?
                        <Skeleton width="100%" height="120px"></Skeleton> :
                        <>
                            <div className="titulo">{titulo}</div>
                            <div className="contenidoTarjeta">
                                <div className="contenedorIcono" style={{ background: `rgba(${color},0.3)` }}>
                                    <FontAwesomeIcon icon={icono} style={{ color: `rgb(${color})`, fontSize: "25px", opacity: 1 }} />
                                </div>
                                <div className="contenido">
                                    {children}
                                </div>
                                <div className="graficaIcono" style={{ display: `${iconoGraficaM ? "flex" : "none"}` }}>
                                    <FontAwesomeIcon id={`iconoGrafica${id}`} icon={faChartSimple} style={{ color: `rgb(${color})`, fontSize: "25px" }} />
                                </div>
                            </div>
                        </>
                }
            </div>
            {mostrarModal && !disabled ? <ModalReporte datos={datos} setStatus={setMostrarModal} status={mostrarModal}></ModalReporte> : null}
        </>

    )
}
export default TarjetaReportes