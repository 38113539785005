import React, { useEffect, useState } from "react";
import './Login.scss'
import logo from '../../assets/img/logosuma.png'
import InputText from "../../components/atoms/InputText/InputText";
import Boton from "../../components/atoms/Boton/Boton";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import { useNavigate } from "react-router-dom";
import { peticionLogin } from "./integracion.js";

const Login = ({setAuth}) => {
    const navigate = useNavigate("")
    const [inputLista,setInputLista] = useState({
        email:{value:null,validationType:"email"},
        password:{value:null,validationType:"empty"}
    })


    useEffect(()=>
    {
        for(const optionValidation in inputLista)
        {
            if(document.getElementById(optionValidation))
            {
                if (optionValidation == "email") {
                    ColorValidation(optionValidation, inputLista, "email")
                }
                else {

                    ColorValidation(optionValidation, inputLista, "empty")
                }
            }

        }
    },[inputLista])


    const ingresar = ()=>
    {
        if(SubmitValidation(inputLista,setInputLista))
        {
            const email = inputLista.email.value
            const password = inputLista.password.value
            peticionLogin(email,password,setAuth,navigate)
                
        }
    }

    return (
        <div className="Login">
            <div className="modal">
                <div className="contenedorLogo">
                    <img src={logo} width={"150px"} alt="" />
                </div>
                <div className="descripcion">
                    Ingrese su dirección de correo electrónico y contraseña para acceder al panel de administración.
                </div>
                <div className="contenedorInputs">
                    <InputText title="Correo electrónico" id="email" obligatorio onChange={(e)=>{UpdateValue(e,"email",inputLista,setInputLista)}}></InputText>
                    <InputText type="password" title="Contraseña" id="password" obligatorio onChange={(e)=>{UpdateValue(e,"password",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="olvidateContrasenia" onClick={()=>{navigate("/recuperar-contraseña")}}>¿Olvidaste tu contraseña?</div>
                <div className="footerModal">

                    <div className="contenedorCHECK">
                        <input type="checkbox"></input><label>Recordar cuenta</label>
                    </div>
                    <div className="botonIngresar">
                        <Boton onClick={()=>{ingresar()}}>Ingresar</Boton>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login