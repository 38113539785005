import React, { useState } from "react";
import './CodigoVerificacion.scss'
import InputCodigo from "../../components/molecules/InputCodigo/InputCodigo";
import logo from '../../assets/img/logosuma.png'
import Boton from "../../components/atoms/Boton/Boton";
import { useNavigate } from "react-router-dom";
const CodigoVerificacion = ()=>
{
    const navigate = useNavigate()
    const [codigo,setCodigo] = useState(null)
    const siguientePaso = ()=>
    {
        if(codigo == "11111")
        {
            alert("Codigo correcto")
            navigate("/reigresar-contrasenia")
        }
        else
        {
            alert("Codigo incorrecto")
            const id = document.getElementById("codigoVerificacion")
            for(let i = 0;i<id.children[0].children[0].children.length;i++)
            {
                id.children[0].children[0].children[i].style.border = "1px solid red"
            }
            
        }
    }
    return(
        <div className="CodigoVerificacion">
            <div className="modal">
            <div className="contenedorLogo">
                    <img src={logo} alt="" width={"150px"}/>
                </div>
                <div className="descripcion">
                    <div className="titulo">Recuperar cuenta</div>
                    <div className="subTitulo">Ingresa el codigo que se envio a tu correo</div>
                </div>
                <div className="inputCodigoF">
                    <InputCodigo setCodigo={setCodigo}></InputCodigo>
                </div>
                <div className="botonesFooter">
                    <Boton onClick={()=>{navigate("/recuperar-contraseña")}} width="50%" background = "black">Regresar</Boton>
                    <Boton width="50%" onClick={()=>{siguientePaso()}} >Continuar</Boton>
                </div>
            </div>
        </div>
    )
}
export default CodigoVerificacion