import React, { useEffect, useState } from "react";
import './EditarPerfil.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";

import Boton from "../../components/atoms/Boton/Boton";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import InputText from "../../components/atoms/InputText/InputText";
const CambiarContraseña = () => {
    //validaciones
    const [inputLista, setInputLista] = useState({
        contraseña: { value: null, validationType: "empty" },
        nuevaConstraseña: { value: null, validationType: "empty" },
    })
    useEffect(() => {
        for (const nombrePropiedad in inputLista) {
            if (document.getElementById(nombrePropiedad)) {
                if (nombrePropiedad == "email") {
                    ColorValidation(nombrePropiedad, inputLista, "email")
                }
                else {

                    ColorValidation(nombrePropiedad, inputLista, "empty")
                }
            }
        }
    }, [inputLista])

    const EnviarDatos = ()=>
    {
        if(SubmitValidation(inputLista,setInputLista))
        {
            if(inputLista.contraseña.value === inputLista.nuevaConstraseña.value )
            {
                alert("se actualizo correctamente")
            }
            else
            {
                alert("error")
            }
        }
    }
    return (
        <div className="InformacionPersonal">
            <DefaultCard padding={true}>

                <div className="contenidoIP">
                    {/* parte izquierda */}
                    <div className="izquierda">
                        <div className="sepearadorInputs">
                            <InputText type="password" value={inputLista?.contraseña.value} obligatorio title="Nueva Contraseña" id="contraseña" onChange={(e) => { UpdateValue(e, "contraseña", inputLista, setInputLista) }}></InputText>
                        </div>
                        <div className="sepearadorInputs">
                            <InputText type="password" value={inputLista?.nuevaConstraseña.value} obligatorio title="Repite Contraseña" id="nuevaConstraseña" onChange={(e) => { UpdateValue(e, "nuevaConstraseña", inputLista, setInputLista) }}></InputText>
                        </div>

                    </div>

                </div>
                <div className="actualizarContenedor">
                    <Boton onClick={()=>{EnviarDatos()}}>Actualizar</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}
export default CambiarContraseña