import React from "react";
import './ButtonBack.scss'
import backIcon from '../../../assets/icons/back.svg'
const ButtonBack = ({onClick})=>
{
    return(
        <div className="ButtonBack">
            <button onClick={onClick}><img src={backIcon}></img></button>
        </div>
    )
}
export default ButtonBack