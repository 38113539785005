import React from "react";
import './BotonLinea.scss'


const BotonLinea = ({ width = "fit-content", children, onClick, icono = null }) => {
    return (
        <button className="BotonLinea" onClick={onClick} style={{ width: width }}>
            <div className="contenedoImgContenido">
                {
                    icono ?
                        <>
                            <img src={icono} alt="" />
                        </> : null
                }
                {children}

            </div>

        </button>
    )
}
export default BotonLinea