import React, { useEffect, useState } from "react";
import './Notifications.scss'
import notificationIcon from '../../../assets/icons/notification.svg'
const Notifications = ({ data }) => {
    const [point, setPoint] = useState(false)
    const [showAlerts, setShowAlerts] = useState(false)
    useEffect(() => {
        setPoint(true)
    }, [data])
    const clickButton = ()=>
    {
        setShowAlerts(!showAlerts)
        setPoint(false)
    }
    return (
        <div className="Notifications" >
            <button onClick={() => { clickButton() }}>
                <img src={notificationIcon} alt="" />
            </button>
            {
                point ?
                    <>
                        <div className="point"></div>
                    </> : null

            }
            {
                showAlerts ?
                    <div className="showAlers">
                        <div className="hedaerNotifications">
                            <div className="title">Todas Las Notificaciones</div>
                            <div className="counter"><div>{data?.length}</div></div>
                        </div>
                        <div className="bodyNotifications">
                           {
                            data?.map((item,key)=>
                            {
                                return(<>
                                    <div className="notify">- {item}</div>
                                </>)
                            })
                           }
                        </div>
                    </div>:
                    null

            }
        </div>
    )
}
export default Notifications