import React from "react";
import { Dropdown } from 'primereact/dropdown';
import './InputLista.scss'
const InputLista = ({value,onChange,options,placeholder,disabled=false,titulo})=>
{
    return(
        <div className="InputLista">
            <div className="contenedorTitulo">
                <div className="titulo">{titulo}</div>
                <div className="obligatorio">*</div>
            </div>
            <Dropdown className="lista" disabled={disabled} value={value} onChange={onChange} options={options} optionLabel="nombre" placeholder={placeholder} filter 
            ></Dropdown>
        </div>
    )
}
export default InputLista