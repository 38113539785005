import React, { useState } from "react";
import './AforosEtiquetas.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import dataTable from '../../assets/json/aforos/qr.json'
import BotonEditar from "../../components/atoms/BotonEditar/BotonEditar";
import BotonEliminar from "../../components/atoms/BotonEliminar/BotonEliminar";
import EstadoActivoInactivo from "../../components/atoms/EstadoActivoInactivo/EstadoActivoInactivo";
import { useNavigate } from "react-router-dom";
import BotonDescargarExel from "../../components/atoms/BotonDescargarExel/BotonDescargarExel";
import BotonDescargar from "../../components/atoms/BotonDescargar/BotonDescargar";
import Boton from "../../components/atoms/Boton/Boton";
const AforosEtiquetas = () => {
    const navigate = useNavigate()
    const [globalFilter, setGlobalFilter] = useState(''); // Estado para el filtro global
    const [accionesId, setAccionesId] = useState()//este estado te regresara el id de la colimna en la que se dio click en las acciones 
    // Función para filtrar los datos basados en el valor de búsqueda
    const filterDataTable = (event) => {
        setGlobalFilter(event.target.value);
    };
    const filtered = dataTable.data.filter(dispositivo => {

        return dispositivo.IDCliente.toLowerCase().includes(globalFilter.toLowerCase()) ||
            dispositivo.IDEmpleado.toLowerCase().includes(globalFilter.toLowerCase())||
            dispositivo.Activo.toLowerCase().includes(globalFilter.toLowerCase())
    });
    // Template para la columna de estado
    const statusTemplate = rowData => {
        return (
            <>
                <div className="centrarEstados">
                    {
                        rowData.Activo == "Activo" ?
                            <>
                                <EstadoActivoInactivo noTexto={false} value={true}></EstadoActivoInactivo>
                            </> :
                            <>
                                <EstadoActivoInactivo noTexto={false} value={false}></EstadoActivoInactivo>
                            </>
                    }
                </div>
            </>
        )
    };
    // Template para la columna de estado
    const actionsTemplate = rowData => {
        return (
            <div className="acomodoBotones2">
                <BotonEditar onClick={() => { onclickEditar(rowData.IDEmpleado) }}>edit</BotonEditar>
                <BotonEliminar onClick={() => { setAccionesId(rowData.IDEmpleado) }}>delete</BotonEliminar>
                <BotonDescargar qrInfo={`${rowData.IDCliente}_${rowData.IDEmpleado}`} qrName={rowData.IDEmpleado}></BotonDescargar>
            </div>
        )
    };
    const onclickEditar = (id) => {
        setAccionesId(id)
        navigate(`/aforos/qr/${id}/editar`)
    }
    return (
        <div className="AforosEtiquetas">
            <DefaultCard>
                <div className="filtrar">
                    <div className="texto">Filtrar</div>
                    <InputText
                        type="search"
                        placeholder="Buscar"
                        value={globalFilter}
                        onChange={filterDataTable}
                    />
                    <BotonDescargarExel nombreArchivo={"Etiquetas"} datos={dataTable}></BotonDescargarExel>
                    <div className="nuevoUsuario">
                        <Boton onClick={()=>{navigate("/aforos/qr/crear")}}>Agregar empleado</Boton>
                    </div>
                </div>

                <DataTable
                    value={filtered}
                    emptyMessage="Sin resultados"
                >
                    {dataTable.header.map((col, i) => {
                        if (col.field == "Activo") {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} body={statusTemplate} />
                            )
                        }
                        if (col.field == "Acciones") {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} body={actionsTemplate} />
                            )
                        }
                        else {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} />
                            )
                        }
                    })}
                </DataTable>
            </DefaultCard>
        </div>
    )
}
export default AforosEtiquetas