import React, { useEffect, useState } from "react";
import './AdministradorUsuariosEditar.scss'
import { useNavigate, useParams } from "react-router-dom";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import InputText from "../../components/atoms/InputText/InputText";
import { Dropdown } from "primereact/dropdown";
import Boton from "../../components/atoms/Boton/Boton";
import Swal from "sweetalert2";
import EstadoActivoInactivo from "../../components/atoms/EstadoActivoInactivo/EstadoActivoInactivo";
import { traerInfoUsuario } from "./integracion";

const AdministradorUsuariosEditar = () => {
    const navigate = useNavigate()
    const id_user = useParams()
    const [estatus, setEstatus] = useState(true)
    const [inputLista, setInputLista] = useState({
        // idUsuario:{ value: null, validationType: "empty"},
        Tipodeusuario: { value: null, validationType: "empty" },//select
        email:{ value: null, validationType: "email"},
        nombre:{ value: null, validationType: "empty"},
        empresa:{ value: null, validationType: "empty"},//select
        password:{ value: null, validationType: "empty"},
        telefono:{ value: null, validationType: "optional"},
    })
    useEffect(() => {
        for (const Elemento in inputLista) {
            if (document.getElementById(Elemento)) {
                ColorValidation(Elemento, inputLista)
            }
        }
    }, [inputLista])
    useEffect(()=>
    {
        traerInfoUsuario(setInputLista,id_user.id)
    },[])


    const etiquetas = [
        { name: 'administrador', code: 'administrador' },
        { name: 'inplant', code: 'inplant' },
        { name: 'coordinador', code: 'coordinador' },
        { name: 'cliente', code: 'cliente' },

    ];
    const empresas = [
        { name: '163 - PLEXUS', code: '163' },
    ];
    const guardarUsuario = () => {
        if (SubmitValidation(inputLista, setInputLista)) {
            inputLista.Estatus = estatus;
            Swal.fire({
                icon: "success",
                title: "Nuevo usuario guardado",
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/administrador/usuarios")
        }
    }
    return (
        <div className="AdministradorUsuariosEditar">
            <DefaultCard>
                <div className="titulo">Editar usuario</div>
                <hr />
                <div className="IdUsuario">
                    <InputText placeholder={"Ingresa el nombre del usuario"} id="nombre" title="Nombre de usuario" onChange={(e)=>{UpdateValue(e,"nombre",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText placeholder={"Ingresa el email del usuario"} id="email" title="Correo electrónico" onChange={(e)=>{UpdateValue(e,"email",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText type="password" placeholder={"Contraeña"} id="password" title="Contraseña" onChange={(e)=>{UpdateValue(e,"password",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="IdUsuario">
                    <InputText type="number" placeholder={"teléfono"} id="telefono" title="teléfono" onChange={(e)=>{UpdateValue(e,"telefono",inputLista,setInputLista)}}></InputText>
                </div>
                <div className="drop">
                    <div className="tituloDropd">Seleccionar el tipo de usuario</div>
                    <Dropdown id="Tipodeusuario" value={inputLista.Tipodeusuario.value} onChange={(e) => { UpdateValue(e, "Tipodeusuario", inputLista, setInputLista) }} options={etiquetas} optionLabel="name" placeholder="Selecciona el ipo de usuario" filter className="dropclass" />
                </div>
                <div className="drop">
                    <div className="tituloDropd">Seleccionar la empresa</div>
                    <Dropdown id="empresa" value={inputLista.empresa.value} onChange={(e) => { UpdateValue(e, "empresa", inputLista, setInputLista) }} options={empresas} optionLabel="name" placeholder="Selecciona la empresa" filter className="dropclass" />
                </div>
                <div className="estatusContenedor">
                    <EstadoActivoInactivo onClick={() => { setEstatus(!estatus) }} value={estatus}></EstadoActivoInactivo>
                </div>
                <div className="guardar">
                    <Boton onClick={() => { guardarUsuario() }}>Guardar usuario</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}
export default AdministradorUsuariosEditar