import React, { useEffect, useState } from "react";
import './Header.scss'
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import ButtonBack from "../../atoms/ButtonBack/ButtonBack";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Notifications from "../../molecules/Notifications/Notifications";
import UserHeader from "../../molecules/UserHeader/UserHeader";
import menuLateralJson from '../../../assets/json/sideMenu.json'
import iconHamburguesa from '../../../assets/icons/menuHamburguesa.svg'
const Header = ({setAuth,setMostrarMenu,mostrarMenu,tamañoPantalla}) => {
    const navigate = useNavigate()
    const ruta = useLocation()
    const [notifications, useNotifications] = useState(["notification1"])
    
    // funcion para simular notificaciones
    // const addNotify = () => {
        //     const newNotification = [...notifications]
        //     newNotification.unshift("newNotification")
        //     useNotifications(newNotification)
        // }
    
    
    
    return (
        <div className="Header">
            <DefaultCard padding={false}>
                <div className="headerStructure">
                    {
                        tamañoPantalla.width < 1024 ?
                            <>
                                <div className="menuHamburguesa">
                                    <div className="imagenContenedor" onClick={()=>{setMostrarMenu(!mostrarMenu)}}>
                                        <img src={iconHamburguesa} alt="" />
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="backButton">
                                    <ButtonBack onClick={() => { navigate(-1) }}></ButtonBack>
                                </div>
                            </>
                    }
                    <div className="userOptions">
                        {/* boton para simular notificaciones */}
                        {/* <button onClick={() => { addNotify() }}>add notication</button> */}
                        <div className="mostrarNombre">
                            {
                                menuLateralJson.map((item, key) => {
                                    if (item.url == ruta.pathname) {
                                        return <>{item.title.toUpperCase()}</>
                                    }
                                    else if (item.subMenu.length != 0) {
                                        return (
                                            <>

                                                {
                                                    item.subMenu.map((itemSubMenu, index) => {

                                                        if (itemSubMenu.url == ruta.pathname) {
                                                            return (<>{itemSubMenu.title.toUpperCase()}</>)
                                                        }

                                                    })

                                                }
                                            </>
                                        )
                                    }
                                })
                            }
                            {
                                ruta.pathname == "/editar-usuario" ? <>EDITAR PERFIL</> : ""
                            }
                        </div>
                        <div className="notifications">
                            <Notifications data={notifications} ></Notifications>
                        </div>
                        <div className="user">
                            <UserHeader setAuth={setAuth}></UserHeader>
                        </div>
                    </div>
                </div>
            </DefaultCard>
        </div>
    )
}
export default Header