import React, { useEffect, useState } from "react";
import './SideMenu.scss'
import menuData from '../../../assets/json/sideMenu.json'
import MenuOption from "../../molecules/MenuOption/MenuOption";

//assets
import logoPlexus from '../../../assets/img/logoPlexus.png'
import Header from "../Header/Header";
import { desEncriptar } from "../../../utilidades/encriptacion";
import { Skeleton } from "primereact/skeleton";
const SideMenu = ({ children,setAuth }) => {
    useEffect(()=>
    {
        const clave = process.env.REACT_APP_CLAVE_INCRIPTACION
        let dataCliente = desEncriptar(localStorage.getItem("dataClient"),clave)
        dataCliente = JSON.parse(dataCliente)
        console.log(dataCliente);
        setDatos(dataCliente)
    },[])
    const [datos,setDatos] = useState(null)
    //escuchador para el responsive
    const [tamañoPantalla, setTamañoPantalla] = useState({
        width: window.innerWidth
    })
    const [mostrarMenu, setMostrarMenu] = useState()

    const cambioDeTamño = () => {
        setTamañoPantalla({
            width: window.innerWidth
        })
    }
    useEffect(() => {
        // Suscribirse al evento de cambio de tamaño
        window.addEventListener('resize', cambioDeTamño);

        // Función de limpieza para desuscribirse del evento
        return () => {
            window.removeEventListener('resize', cambioDeTamño);
        }
    }, [])
    useEffect(() => {
        if (tamañoPantalla.width > 1023) {
            setMostrarMenu(true)
        }
    }, [tamañoPantalla])

    useEffect(()=>
    {
        const menu = document.getElementById("menu")
        if(mostrarMenu)
        {
            menu.style.transition = "all 1s"
            menu.style.display = "initial"
        }
        else{
            menu.style.transition = "all 1s"
            menu.style.display = "none"
            
        }
    },[mostrarMenu])
    console.log("datos",datos);
    return (

        <div className="sideMenu">
                        <div id="menu" className="menu">
                            <div className="botonRegresar" onClick={()=>{setMostrarMenu(false)}}>
                                {tamañoPantalla.width < 1024?<div>{">"}</div>:null}
                            </div>
                            {/* logo de la epresa */}
                            <div className="logoCompany">
                                {
                                    datos?
                                    <>
                                        <img src={datos?.data[0]?.LOGO} className="logoMenu" alt="" />
                                    </>:
                                    <Skeleton/>

                                }
                            </div>
                            {menuData.map((item, key) => {
                                return (
                                    <MenuOption menuData={menuData[key]} index={key} />
                                )
                            })}
                        </div>
            




            <div className="fixedHeader"><Header setAuth={setAuth} mostrarMenu={mostrarMenu} setMostrarMenu={setMostrarMenu} tamañoPantalla={tamañoPantalla}></Header></div>
            <div className="content">{children}</div>
        </div>
    )
}
export default SideMenu 
