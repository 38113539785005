import React, { useEffect, useState } from "react";
import './EditarPerfil.scss'
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import InputImagen from "../../components/molecules/InputImagen/InputImagen";
import InputText from "../../components/atoms/InputText/InputText";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../utilidades/Validaciones";
import InputRadio from "../../components/atoms/InputRadio/InputRadio";
import InputLista from "../../components/atoms/InputLista/InputLista";
import InputCalendario from "../../components/atoms/InputCalendario/InputCalendario";
import EstadoActivoInactivo from "../../components/atoms/EstadoActivoInactivo/EstadoActivoInactivo";
import Boton from "../../components/atoms/Boton/Boton";
import { desEncriptar } from "../../utilidades/encriptacion";

export const InformacionPersonal = () => {
    const [imagen, setImagen] = useState()
    //validaciones
    const [inputLista, setInputLista] = useState({
        nombre: { value: "Jorge Antonio Fuerte Diaz", validationType: "empty" },
        telefono: { value: "3323712051", validationType: "empty" },
        // sexo: { value: "Hombre", validationType: "empty" },
        rol: { value: { nombre: "Implant" }, validationType: "empty" },
        // puesto: { value: { nombre: "Puest1" }, validationType: "empty" },
        email: { value: "testclientes@pelxus.com", validationType: "email" },
        // fechaNacimiento: { value: new Date(1999, 8, 3), validationType: "empty" },
        empresa: { value: {nombre:"Plexus"}, validationType: "empty" },
        estado: { value: true, validationType: "empty" },
    })
   
    useEffect(() => {
        for (const nombrePropiedad in inputLista) {
            if (document.getElementById(nombrePropiedad)) {
                if (nombrePropiedad == "email") {
                    ColorValidation(nombrePropiedad, inputLista, "email")
                }
                else {

                    ColorValidation(nombrePropiedad, inputLista, "empty")
                }
            }
        }
    }, [inputLista])
    // fincion para cambiar el inputList desde los radios de sexo
    const cambiarSexo = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.sexo.value = e.value
        setInputLista(copiaLista)
    }
    // lista y preparacion de los roles 
    const roles = [
        { nombre: "Implant" }
    ]
    const cambiarRol = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.rol.value = e.value
        setInputLista(copiaLista)
    }
    // lista y preparacion de los puesto 
    const puestos = [
        { nombre: "Puest1" },
        { nombre: "Puest2" },
        { nombre: "Puest3" },
        { nombre: "Puest4" },
        { nombre: "Puest5" },
        { nombre: "Puest6" },
        { nombre: "Puest7" },
        { nombre: "Puest8" },
        { nombre: "Puest9" },
        { nombre: "Puest10" },
        { nombre: "Puest11" },
        { nombre: "Puest12" },
        { nombre: "Puest13" },
        { nombre: "Puest14" },
        { nombre: "Puest15" },
        { nombre: "Puest16" },
        { nombre: "Puest17" },
        { nombre: "Puest18" },
        { nombre: "Puest19" },
        { nombre: "Puest20" },
        { nombre: "Puest21" },
        { nombre: "Puest22" },
        { nombre: "Puest23" },
        { nombre: "Puest24" },
    ]
    const cambiarPuesto = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.puesto.value = e.value
        setInputLista(copiaLista)
    }
    //lista y preparacion de cambiarFecha
    const cambiarFecha = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.fechaNacimiento.value = e.value
        setInputLista(copiaLista)
    }
    //actualizar input de empresa
    const cambiarEmpresa = (e) => {
        const copiaLista = { ...inputLista }
        copiaLista.empresa.value = e.value
        setInputLista(copiaLista)
    }
    const empresas = [
        {nombre:"Plexus"},
        {nombre:"beterware"},
        {nombre:"other"},
    ]
    //cambiar estado a activo o inactivo
    const cambiarEstado = () => {
        const copiaLista = { ...inputLista }
        copiaLista.estado.value =! copiaLista.estado.value 
        setInputLista(copiaLista)
    }
    //subir datos a la api
    const EnviarDatos = ()=>
    {
        if(SubmitValidation(inputLista,setInputLista))
        {
            alert("se actualizo correctamente")
        }
        else
        {
            alert("error")
        }
    }
    return (
        <div className="InformacionPersonal">
            <DefaultCard padding={true}>
                {/* <div className="subirImagen">
                    <InputImagen setImg={setImagen} img={imagen}></InputImagen>
                </div> */}
                <div className="contenidoIP">
                    {/* parte izquierda */}
                    <div className="izquierda">
                        <InputText disabled={true} value={inputLista?.nombre.value} obligatorio title="Nombre" id="nombre" onChange={(e) => { UpdateValue(e, "nombre", inputLista, setInputLista) }}></InputText>
                        {/* input radios sexo */}
                        {/* <div className="tituloRdiosSexo">Genero:</div> */}
                        <div className="sexoRadio">
                            {/* <InputRadio checked={inputLista.sexo.value == "Hombre" ? true : false} name={"sexo"} title="Hombre" value={"Hombre"} onChange={(e) => { cambiarSexo(e) }}></InputRadio>
                            <InputRadio checked={inputLista.sexo.value == "Mujer" ? true : false} name={"sexo"} title="Mujer" value={"Mujer"} onChange={(e) => { cambiarSexo(e) }}></InputRadio>
                        <InputRadio checked={inputLista.sexo.value == "Indefinido" ? true : false} name={"sexo"} title="Indefinido" value={"Indefinido"} onChange={(e) => { cambiarSexo(e) }}></InputRadio> */}
                        </div>
                        <div className="sepearadorInputs">
                            <InputLista titulo="Seleccionar Rol:" disabled options={roles} value={inputLista.rol.value} onChange={(e) => { cambiarRol(e) }}></InputLista>
                        </div>
                        <InputText disabled={true} value={inputLista?.telefono.value} obligatorio title="Teléfono" id="telefono" onChange={(e) => { UpdateValue(e, "telefono", inputLista, setInputLista) }}></InputText>
                        {/* <div className="sepearadorInputs">
                            <InputLista titulo="Seleccionar puesto laboral:" options={puestos} value={inputLista.puesto.value} onChange={(e) => { cambiarPuesto(e) }}></InputLista>
                        </div> */}

                    </div>
                    {/* lado derecho */}
                    <div className="derecha">
                        <div className="sepearadorInputs">
                            <InputText disabled value={inputLista?.email.value} obligatorio title="Email" id="email" onChange={(e) => { UpdateValue(e, "email", inputLista, setInputLista) }}></InputText>
                        </div>
                        {/* <div className="sepearadorInputs">
                            <InputCalendario titulo={"Cumpleaños"} onChange={(e) => { cambiarFecha(e) }} value={inputLista.fechaNacimiento.value}></InputCalendario>
                        </div> */}
                        <div className="sepearadorInputs">
                            <InputLista  titulo="Seleccionar Empresa" disabled options={empresas} value={inputLista.empresa.value} onChange={(e) => { cambiarEmpresa(e) }}></InputLista>
                        </div>
                        <div className="sepearadorInputs alinearEstado">
                            <EstadoActivoInactivo value={inputLista.estado.value} onClick={()=>{cambiarEstado()}}></EstadoActivoInactivo>
                        </div>
                    </div>
                </div>
                <div className="actualizarContenedor">
                    <Boton onClick={()=>{EnviarDatos()}}>Actualizar</Boton>
                </div>
            </DefaultCard>
        </div>
    )
}