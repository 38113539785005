import React from "react";
import './Boton.scss'


const Boton = ({width="fit-content",children,onClick,background,className})=>
{
    return(
        <button className={`Boton ${className}`} onClick={onClick} style={{width:width,background:background}}>{children}</button>
    )
}
export default Boton