import React from "react";
import './InputText.scss'



const InputText = ({ type="text",title = "title", obligatorio = false, placeholder, id, onChange,value,disabled }) => {
    return (
        <div className="InputText">
            <div className="contenedorTitulo">
                <div className="titulo">{title}</div>
                {
                    obligatorio ?
                        <>
                            <div className="obligatorio">*</div>
                        </>:null
                }
            </div>
            <input type={type} disabled={disabled} value={value} id={id} onChange={onChange} placeholder={placeholder}  className="InputText" />
        </div>
    )
}
export default InputText