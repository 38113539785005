import React, { useEffect, useState } from "react";
import "./AdministradorUsuarios.scss"
import DefaultCard from "../../components/atoms/DefaultCard/DefaultCard";
import { InputText } from "primereact/inputtext";
import BotonDescargarExel from "../../components/atoms/BotonDescargarExel/BotonDescargarExel";
import { useNavigate } from "react-router-dom";
import EstadoActivoInactivo from "../../components/atoms/EstadoActivoInactivo/EstadoActivoInactivo";
import BotonEditar from "../../components/atoms/BotonEditar/BotonEditar";
import BotonEliminar from "../../components/atoms/BotonEliminar/BotonEliminar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import dataTable from '../../assets/json/administrador/usuarios.json'
import Boton from "../../components/atoms/Boton/Boton";
import { consultarUsuarios } from "./integracion";


const AdministradorUsuarios = () => {
    const navigate = useNavigate()
    const [globalFilter, setGlobalFilter] = useState(''); // Estado para el filtro global
    const [accionesId, setAccionesId] = useState()//este estado te regresara el id de la colimna en la que se dio click en las acciones 
    const [dataUser,setDataUsers] = useState()
    // Función para filtrar los datos basados en el valor de búsqueda
    const filterDataTable = (event) => {
        setGlobalFilter(event.target.value);
    };

    useEffect(()=>
    {
        consultarUsuarios(setDataUsers)
    },[])
    const filtered = dataUser?.data.filter(dispositivo => {
        return dispositivo.TELEFONO?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            dispositivo.EMPRESA?.toLowerCase().includes(globalFilter.toLowerCase())||
            dispositivo.NOMBRE?.toLowerCase().includes(globalFilter.toLowerCase())||
            dispositivo.CORREO?.toLowerCase().includes(globalFilter.toLowerCase())||
            dispositivo.TIPO_USUARIO?.toLowerCase().includes(globalFilter.toLowerCase())||
            dispositivo.ACTIVO?.toLowerCase().includes(globalFilter.toLowerCase())
    });
    // Template para la columna de estado
    const statusTemplate = rowData => {
     
        return (
            <>
                <div className="centrarEstados">
                    {
                        rowData.ACTIVO == "Activado" ?
                            <>
                                <EstadoActivoInactivo noTexto={false} value={true}></EstadoActivoInactivo>
                            </> :
                            <>
                                <EstadoActivoInactivo noTexto={false} value={false}></EstadoActivoInactivo>
                            </>
                    }
                </div>
            </>
        )
    };
    // Template para la columna de estado
    const actionsTemplate = rowData => {
        return (
            <div className="acomodoBotones2">
                <BotonEditar onClick={() => { onclickEditar(rowData.ID) }}>edit</BotonEditar>
                <BotonEliminar onClick={() => { setAccionesId(rowData.ID) }}>delete</BotonEliminar>
            </div>
        )
    };
    const onclickEditar = (id) => {
        setAccionesId(id)
        navigate(`/administrador/usuarios/${id}/editar`)
    }
    return (
        <div className="AdministradorUsuarios">
            <DefaultCard>
                <div className="filtrar">
                    <div className="texto">Filtrar</div>
                    <InputText
                        type="search"
                        placeholder="Buscar"
                        value={globalFilter}
                        onChange={filterDataTable}
                    />
                    <BotonDescargarExel nombreArchivo={"Etiquetas"} datos={dataUser}></BotonDescargarExel>
                    <div className="nuevoUsuario">
                        <Boton onClick={()=>{navigate("/administrador/usuarios/crear")}}>Agregar nuevo</Boton>
                    </div>
                </div>

                <DataTable
                    value={filtered}
                    emptyMessage="Sin resultados"
                    paginator rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                >
                    {dataUser?.header.map((col, i) => {
                        if (col.field == "ACTIVO") {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} body={statusTemplate} />
                            )
                        }
                        if (col.field == "Acciones") {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} body={actionsTemplate} />
                            )
                        }
                        else {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} />
                            )
                        }
                    })}
                </DataTable>
            </DefaultCard>
        </div>
    )
}
export default AdministradorUsuarios