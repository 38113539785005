import React, { useRef } from "react";
import './BotonDescargar.scss'
import descargar from '../../../assets/icons/descargar.svg'
import QRCode from 'qrcode';
const BotonDescargar = ({ qrInfo, onClick, qrName }) => {

    const descargarQR = async () => {
        try {
            // Genera el código QR utilizando la biblioteca 'qrcode'
            const canvas = await QRCode.toCanvas(qrInfo, { errorCorrectionLevel: 'H' });
      
            // Convierte el canvas en una imagen y descárgala
            const dataUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `${qrName}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            console.error('Error al generar o descargar el código QR:', error);
          }
    }
    return (
        <div className="BotonDescargar">
            <button onClick={() => { descargarQR() }}><img width={"20px"} src={descargar} alt="" /></button>
        </div>
    )
}
export default BotonDescargar